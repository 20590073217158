// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
let children = [
    {
        id: 'success_story',
        title: 'Success Story',
        type: 'item',
        url: '/get-social',
        breadcrumbs: false
    },
    {
        id: 'leaders_voice',
        title: 'Leaders Voice',
        type: 'item',
        url: '/leadersvoice',
        breadcrumbs: false
    },
    {
        id: 'news_notice',
        title: 'News & Notice',
        type: 'item',
        url: '/news-events/news',
        breadcrumbs: false
    },
    {
        id: 'events',
        title: 'Events',
        type: 'item',
        url: '/news-events/events',
        breadcrumbs: false
    },
    {
        id: 'projectengagement',
        title: 'Task & Project Engagement',
        type: 'item',
        url: '/tasks',
        breadcrumbs: false
        // children: [
        //     {
        //         id: 'task',
        //         title: 'Task',
        //         type: 'collapse',
        //         url: '/tasks',
        //         breadcrumbs: false
        //     },
        //     {
        //         id: 'project',
        //         title: 'Project',
        //         type: 'collapse',
        //         url: '/projects',
        //         breadcrumbs: false
        //     },
        //     {
        //         id: 'goals',
        //         title: 'Goals',
        //         type: 'item',
        //         url: '/goals',
        //         breadcrumbs: false
        //     }
        // ]
    },
    {
        id: 'meeting_management',
        title: 'Meeting Engagement ',
        type: 'item',
        url: '/meeting',
        breadcrumbs: false
        // children: [
        //     {
        //         id: 'meeting',
        //         title: 'Meetings',
        //         type: 'item',
        //         url: '/meeting',
        //         breadcrumbs: false
        //     },
        //     {
        //         id: 'recordMeetings',
        //         title: 'Meeting Records',
        //         type: 'collapse',
        //         url: '/meeting-records',
        //         breadcrumbs: false
        //     }
        // ]
    },

    {
        id: 'knowledge_skills',
        title: 'Knowledge & Skills',
        type: 'item',
        url: '/self-study',
        breadcrumbs: false
        // children: [
        //     {
        //         id: 'companylibrary',
        //         title: 'Company Library',
        //         type: 'collapse',
        //         url: '/company-library',
        //         breadcrumbs: false
        //     },
        //     {
        //         id: 'selfStudy',
        //         title: 'My Knowledge & skill',
        //         type: 'collapse',
        //         url: '/self-study',
        //         breadcrumbs: false
        //     }
        // ]
    },

    {
        id: 'movement_visits',
        title: 'Movement & Visits',
        type: 'item',
        url: '/movement-visit',
        breadcrumbs: false
    },
    {
        id: 'recognitions',
        title: 'Recognition & Rewards',
        type: 'item',
        url: '/reward-recognition',
        breadcrumbs: false
    },
    {
        id: 'policy',
        title: 'Policy & Procedures',
        type: 'item',
        url: '/policy-procedure',
        breadcrumbs: false
    }
];

const Organization = {
    id: 'organization',
    title: 'Organization Communication & Engagement',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default Organization;
