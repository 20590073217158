/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import Reac, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import { Link, InputLabel, OutlinedInput, Snackbar } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import parse from 'html-react-parser';
import axios from 'lib/axios';
import Comment from '../../assets/static_images/comment.svg';
import Reacts from '../../components/Reacts';
import CommentList from 'components/comment/CommentList';
import CreateComment from 'components/comment/CreateComment';
import ThreeDot from '../../assets/static_images/threedot.svg';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import Cross from '../../assets/static_images/cross.svg';
import Gallery from 'components/Slider';
import Button from '@mui/material/Button';
import Like from '../../assets/static_images/like.svg';
import Love from '../../assets/static_images/love.svg';
import Haha from '../../assets/static_images/haha.svg';
import Wow from '../../assets/static_images/wow.svg';
import Sad from '../../assets/static_images/sad.svg';
import Angry from '../../assets/static_images/angry.svg';
import Blank from '../../assets/static_images/blank.svg';
import GalleryIcon from '../../assets/static_images/galleryicon.svg';
import CalanderIcon from '../../assets/static_images/calander.svg';
import ClockIcon from '../../assets/static_images/clock.svg';
import EventDetails from 'components/modals/EventDetails';
import Attending from '../../assets/static_images/attending.svg';
import NotAttendig from '../../assets/static_images/notattending.svg';
import PdfIcon from '../../assets/static_images/pdficon.svg';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import PolicyViewers from 'components/modals/PolicyViewers';
import CreatePolicy from 'components/modals/Policy';
import { Alert } from '@mui/lab';
import DeleteModal from 'components/modals/DeleteModal';
import { Box } from '@mui/material';
import FileDownload from 'js-file-download';
import ViewReactModal from 'components/modals/ViewReactModal';
import { Avatar } from '@mui/material';
import PolicyCommentBox from './PolicyCommentBox';
import { socket } from 'socket';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1E7C88' : '#1E7C88'
    }
}));

const Policy = ({ policy, reacts, setPolicies, formSidebar = false, lastPostRef, index, policies }) => {
    const theme = useTheme();
    const [policyUsers, setPolicyUsers] = useState(
        policy.policy_users.map((item) => {
            return { ...item.user, seen: item.seen, seen_at: item.updatedAt };
        })
    );
    const [attendenceCount, setAttendenceCount] = useState(() => {
        let count = 0;
        policyUsers.map((user) => {
            if (user.seen == true) {
                count++;
            }
        });

        return count;
    });

    const [percent, setPercent] = useState(() => {
        return (attendenceCount / policyUsers.length) * 100;
    });
    const [openViewReactModal, setOpenViewReactModal] = useState(false);
    async function submitResponse(response) {
        axios
            .post(`/policies/response`, {
                policy_id: policy.policy_id,
                response: response
            })
            .then(({ data }) => {
                if (data.success) {
                    setAttendenceCount((attendenceCount) => attendenceCount + 1);
                }
            })
            .catch((err) => console.log(err));
    }
    const [openModal, setOpenModal] = useState(false);

    const [content, setContent] = useState('');
    const [contentUrl, setContentUrl] = useState('');
    const [user, setUser] = useState(policy.user);
    const textInput = useRef(null);
    const [openGallery, setOpenGallery] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showParticipants, setShowParticipants] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [reactOpen, setReactOpen] = useState(false);
    const [reacted, setReacted] = useState(false);
    const [reaction, setReaction] = useState(null);
    const [reactionImg, setReactionImg] = useState(Blank);
    const [commentOpen, setCommentOpen] = useState(false);
    const [reactCount, setReactCount] = useState(Number(policy.policyReactCount));
    const [comments, setComments] = useState([]);
    const [limit, setLimit] = useState(50);
    const [offset, setOffset] = useState(2);
    const [viewAllComments, setViewAllComments] = useState(true);
    const [created, setCreated] = useState(new Date(policy.createdAt));
    const [openSnack, setShowSnack] = useState(false);
    const [openOption, setOpenOption] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [commentCount, setCommentCount] = useState(Number(policy.policyCommentCount));

    const [showComments, setShowComments] = useState(formSidebar ? true : false);

    function handleSnackClose() {
        setShowSnack(false);
    }
    useEffect(() => {
        let id = policy.policy_id.toString();
        let url = `/Uploads/${user.user_id}/Policies/${id}/index.html`;
        setContentUrl(`${process.env.REACT_APP_BACKEND_URL}/${url}`);
        axios
            .get(`${url}`)
            .then(({ data }) => {
                setContent(data);
            })
            .catch((err) => console.log(err));
        axios.get(`/policies/reacted/${policy.policy_id}`).then(({ data }) => {
            if (data.success) {
                setReacted(data.reacted);
                if (data.react !== null) {
                    setReaction(data.react.react.react_name);
                    if (data.react.react.react_id == 1) {
                        setReactionImg(Like);
                    } else if (data.react.react.react_id == 2) {
                        setReactionImg(Love);
                    } else if (data.react.react.react_id == 3) {
                        setReactionImg(Haha);
                    } else if (data.react.react.react_id == 4) {
                        setReactionImg(Wow);
                    } else if (data.react.react.react_id == 5) {
                        setReactionImg(Sad);
                    } else if (data.react.react.react_id == 6) {
                        setReactionImg(Angry);
                    }
                }
            }
        });
    }, []);

    function urlify(text) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a target="_target" href="' + url + '">' + url + '</a>';
        });
    }
    const adjustReact = async () => {
        await axios
            .delete('/policy-reacts/destroy', { data: { policy_id: policy.policy_id } })
            .then(({ data }) => {
                if (data.success) {
                    setReacted(false);
                }
            })
            .catch((err) => console.log(err));
    };
    const fetchComments = async () => {
        const formData = new FormData();
        formData.append('offset', parseInt(offset));
        formData.append('limit', parseInt(limit));

        await axios.post(`/policy-comments/${policy.policy_id}`, formData).then(({ data }) => {
            if (data.success) {
                setComments([...comments, ...data.comments]);
                setLimit(data.count);
                setOffset(data.comments.length);
            }
        });
    };

    useEffect(() => {
        fetchComments();
    }, [showComments]);
    async function download_file(path) {
        await axios
            .post(`/download`, {
                file_path: path
            })
            .then((res) => {
                FileDownload(res.data, res.data.file_name);
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        listen_to_channel();
    }, []);

    function listen_to_channel() {
        socket.on('policy_react_added', (post_id) => {
            if (post_id == policy.policy_id) {
                setReactCount((prev) => prev + 1);
            }
        });
        socket.on('policy_react_removed', (post_id) => {
            if (post_id == policy.policy_id) {
                setReactCount((prev) => prev - 1);
            }
        });
    }
    return (
        <Box
            sx={{ marginY: !formSidebar && '1rem', minHeight: '100%', minWidth: '100%' }}
            ref={index === policies.length - 1 ? lastPostRef : null}
        >
            <Box
                sx={{
                    borderRadius: !formSidebar && '10px',
                    padding: '1rem',
                    width: '100%',
                    backgroundColor: '#fff',
                    border: !formSidebar && '1px solid #1E7C8840',
                    position: 'relative'
                }}
            >
                <Box>
                    {/* card header  */}
                    <Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" gap="0.5rem">
                                <Typography sx={{ opacity: '0.6' }}>{created.toDateString()}</Typography>
                                <Typography sx={{ opacity: '0.6' }}>{created.toLocaleTimeString('en-US')}</Typography>
                            </Box>
                            {JSON.parse(window.localStorage.getItem('authUser')).user_id == policy.user_id && (
                                <Grid
                                    item
                                    sm={1}
                                    className="cursor-pointer"
                                    onClick={() => {
                                        // setOpenModal(true);
                                        setOpenOption(!openOption);
                                    }}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img src={ThreeDot} alt="dot" style={{}} />
                                </Grid>
                            )}
                        </Box>
                        {openOption ? (
                            <Grid
                                item
                                sx={{
                                    zIndex: '1024',
                                    position: 'absolute',
                                    top: '2.5rem',
                                    right: '0rem',
                                    width: '6rem',
                                    height: '10rem'
                                }}
                            >
                                <Card sx={{ borderRadius: '10px', width: '100%' }}>
                                    <Grid container display="flex" direction="column" aligItems="center" justifyContent="center">
                                        <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{ borderBottom: '1px solid #000000' }}
                                            onClick={() => {
                                                setOpenModal(true);
                                            }}
                                            className="cursor-pointer"
                                        >
                                            <Typography>Edit</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            display="flex"
                                            justifyContent="center"
                                            aligItems="center"
                                            className="cursor-pointer"
                                            onClick={() => {
                                                setOpenDeleteModal(true);
                                            }}
                                        >
                                            <Typography>Delete</Typography>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        ) : (
                            <></>
                        )}
                    </Box>
                    {/* card  body  */}
                    <Box>
                        <Box className="content-body" marginY="1rem">
                            <Box>
                                <Typography variant="h5" sx={{ marginY: '0.6rem' }}>
                                    {policy.policy_title}
                                </Typography>
                                {urlify(policy.plain_text_body).length > 150 ? (
                                    <Box>
                                        {showMore ? urlify(policy.plain_text_body) : `${urlify(policy.plain_text_body).substring(0, 150)}`}
                                        {!showMore ? (
                                            <Button
                                                className="btn"
                                                onClick={() => {
                                                    submitResponse(true);
                                                    setShowMore(!showMore);
                                                }}
                                            >
                                                ...Show more
                                            </Button>
                                        ) : (
                                            <Button
                                                className="btn"
                                                onClick={() => {
                                                    submitResponse(true);
                                                    setShowMore(!showMore);
                                                }}
                                            >
                                                ...Show Less
                                            </Button>
                                        )}
                                    </Box>
                                ) : (
                                    <Box>{urlify(policy.plain_text_body)}</Box>
                                )}
                            </Box>
                        </Box>
                        {policy.attachemts && policy.attachemts.length > 0 ? (
                            policy.attachemts.map((attachemt) => {
                                return (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        marginBottom="1rem"
                                        onClick={() => {
                                            submitResponse(true);
                                        }}
                                    >
                                        <img src={PdfIcon} width={32} height={32} alt="pdficon"></img>
                                        <Box
                                            sx={{ marginLeft: '0.5rem' }}
                                            className="cursor-pointer"
                                            onClick={() => {
                                                download_file(`${attachemt.url}`);
                                            }}
                                        >
                                            <Typography sx={{ fontSize: '16px' }}>{attachemt.name}</Typography>
                                        </Box>
                                    </Box>
                                );
                            })
                        ) : (
                            <></>
                        )}
                        <BorderLinearProgress variant="determinate" value={percent} />
                        <Grid container>
                            <Grid item sm={12} xs={12} sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.10)' }} marginBottom="0.5rem">
                                <Grid
                                    container
                                    sm={12}
                                    display="flex"
                                    alignItems="center"
                                    sx={{ padding: '0px', width: '100%' }}
                                    gap={1}
                                    my={2}
                                >
                                    {/* response stats  */}
                                    <Grid
                                        item
                                        sm={8}
                                        onClick={() => {
                                            setShowParticipants(!showParticipants);
                                        }}
                                    >
                                        <Grid
                                            container
                                            sm={12}
                                            display="flex"
                                            alignItems="center"
                                            spacing={0}
                                            sx={{ padding: '0px', width: '100%' }}
                                            gap={1}
                                        >
                                            <Grid item lg={3} sm={4} display="flex" alignItems="center">
                                                <Grid
                                                    container
                                                    display="flex"
                                                    alignItems="center"
                                                    sx={{ padding: '0px', cursor: 'pointer' }}
                                                >
                                                    {policyUsers.map((user, index) => {
                                                        if (index <= 1) {
                                                            let cls = index == 0 ? 'img-fit' : 'img-fit dispimg';
                                                            return (
                                                                <Grid
                                                                    item
                                                                    display="flex"
                                                                    aligItems="center"
                                                                    sx={{ height: '2.5rem', width: '2.5rem' }}
                                                                >
                                                                    <Avatar
                                                                        src={user.first_name}
                                                                        className={cls}
                                                                        style={{ width: '100%' }}
                                                                        alt={user.first_name}
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                        if (index == 2) {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    display="flex"
                                                                    sx={{
                                                                        width: '2.5rem',
                                                                        height: '2.5rem',
                                                                        marginLeft: '-1rem',
                                                                        zIndex: '200'
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        src={user.first_name}
                                                                        className="img-fit"
                                                                        style={{ width: '100%' }}
                                                                        alt={user.first_name}
                                                                    />
                                                                </Grid>
                                                            );
                                                        }
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item lg={8} sm={7} display="flex" aligItems="center" sx={{ cursor: 'pointer' }}>
                                                <Grid container display="flex" aligItems="center" sx={{ paddingX: '0px' }}>
                                                    <Grid item sx={{ opacity: '0.6' }}>
                                                        seen {attendenceCount} of {policyUsers.length}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* event response section  */}
                                    <Grid item sm={4}>
                                        <Grid
                                            container
                                            display="flex"
                                            aligItems="center"
                                            justifyContent="end"
                                            sx={{ paddingX: '0px', cursor: 'pointer' }}
                                        >
                                            <Grid item sx={{ opacity: '0.6' }}>
                                                {policyUsers.length - attendenceCount} remaining
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* reaction section  */}
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="1rem">
                        <Box display="flex" alignItems="center" gap="1rem">
                            {/* react */}
                            <Box display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
                                    <Box
                                        className="cursor-pointer"
                                        onClick={() => {
                                            adjustReact();
                                            setReactOpen(!reactOpen);
                                        }}
                                    >
                                        <Box>
                                            {reacted ? (
                                                <Box display="flex" alignItems="center" gap="0.5rem">
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <img
                                                            src={reactionImg}
                                                            alt="icn"
                                                            style={{
                                                                height: '1rem',
                                                                width: '1rem'
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>{reaction}</Typography>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box display="flex" alignItems="center" gap="0.5rem">
                                                    <Box display="flex" alignItems="center" justifyContent="center">
                                                        <img
                                                            src={Blank}
                                                            alt="icn"
                                                            style={{
                                                                height: '1rem',
                                                                width: '1rem'
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>Like</Typography>
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>

                                    {reactOpen ? (
                                        <Grid
                                            item
                                            sx={{
                                                zIndex: '1024',
                                                position: 'absolute',
                                                top: '-4rem',
                                                left: '1.5rem',
                                                width: '23rem'
                                            }}
                                        >
                                            <Reacts
                                                modelValue={policy}
                                                reacts={reacts}
                                                setReactOpen={setReactOpen}
                                                setReacted={setReacted}
                                                setReactCount={setReactCount}
                                                setReactionImg={setReactionImg}
                                                setReaction={setReaction}
                                                model={'policy'}
                                            />
                                        </Grid>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            </Box>
                            {/* comment */}
                            <Box
                                className="cursor-pointer"
                                onClick={() => {
                                    setCommentOpen(!commentOpen);
                                }}
                                display="flex"
                                alignItems="center"
                                gap="0.5rem"
                            >
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    <img
                                        src={Comment}
                                        alt="icn"
                                        style={{
                                            height: '1rem',
                                            width: '1rem'
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>Comment</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {/* show comments */}
                        <Box
                            className="cursor-pointer"
                            onClick={() => {
                                setShowComments(!showComments);
                            }}
                        >
                            <Typography sx={{ opacity: '0.6', fontSize: '0.85rem' }}>{`${commentCount} Comments`}</Typography>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        alignItems="center"
                        sx={{ gap: '0.25rem', cursor: 'pointer' }}
                        onClick={() => {
                            setOpenViewReactModal(true);
                        }}
                    >
                        {reacted ? <Typography variant="subtitle1">You</Typography> : <></>}
                        {reactCount > 0 && (
                            <Typography variant="h6">
                                {reacted ? 'and' : ''} {reactCount} {reactCount > 1 ? "other's" : 'other'}
                            </Typography>
                        )}
                        {(reactCount > 0 || reacted) && <Typography variant="subtitle1">reacted on this event</Typography>}
                    </Box>
                    {/* comments section  */}
                    {commentOpen && (
                        <Grid item sm={12}>
                            <CreateComment
                                setComments={setComments}
                                comments={comments}
                                post={policy}
                                placeholder={'Write Comment ..'}
                                model={'policy'}
                                showSnack={(isError, message) => {
                                    setSnackOptions({ isError, message });
                                    setShowSnack(true);
                                }}
                                setCommentCount={setCommentCount}
                            />
                        </Grid>
                    )}
                    {/* user comments */}
                    {showComments && comments.length > 0 && (
                        <Box>
                            {comments.map((comment) => {
                                return (
                                    <PolicyCommentBox
                                        setComments={setComments}
                                        setCommentCount={setCommentCount}
                                        comment={comment}
                                        type="policy"
                                    />
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </Box>
            {showParticipants ? (
                <Modal
                    keepMounted
                    open={showParticipants}
                    onClose={setShowParticipants}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <PolicyViewers policyUsers={policyUsers} setShowParticipants={setShowParticipants} />
                </Modal>
            ) : (
                <></>
            )}

            {openDeleteModal ? (
                <Modal
                    keepMounted
                    open={openDeleteModal}
                    onClose={setOpenDeleteModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <DeleteModal
                        setOpenDeleteModal={setOpenDeleteModal}
                        url={`/policies/delete`}
                        id={policy.policy_id}
                        showSnack={(isError, message) => {
                            setSnackOptions({ isError, message });
                            setShowSnack(true);
                        }}
                        setPosts={setPolicies}
                        model={`policy`}
                    />
                </Modal>
            ) : (
                <></>
            )}
            <Grid container sm={12}>
                {openModal && (
                    <Modal
                        keepMounted
                        open={openModal}
                        onClose={setOpenModal}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <CreatePolicy
                            setOpenModal={setOpenModal}
                            policy={policy}
                            setPolicies={setPolicies}
                            showSnack={(isError, message) => {
                                setSnackOptions({ isError, message });
                                setShowSnack(true);
                            }}
                            setOpenOption={setOpenOption}
                        />
                    </Modal>
                )}
            </Grid>
            {openViewReactModal && (
                <ViewReactModal
                    post={policy}
                    type="policy"
                    endpoint="/policy-reacts/list"
                    setOpenModal={setOpenViewReactModal}
                    openModal={openViewReactModal}
                />
            )}
            <Snackbar
                open={openSnack}
                autoHideDuration={5000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ zIndex: '200000' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Policy;
