/* eslint-disable prettier/prettier */
import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import FrontLayout from 'layout/FrontLayout/index';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const VerifyOtp = Loadable(lazy(() => import('pages/auth/VerifyOtp')));
const Companies = Loadable(lazy(() => import('pages/companies/index')));
const Employees = Loadable(lazy(() => import('pages/employees/index')));
const Landing = Loadable(lazy(() => import('pages/landing/index')));
const Divisions = Loadable(lazy(() => import('pages/address/divisions/index')));
const Districts = Loadable(lazy(() => import('pages/address/districts/index')));
const Locations = Loadable(lazy(() => import('pages/address/locations/index')));
const Leadersvoice = Loadable(lazy(() => import('pages/leaders-voice/index')));
const Events = Loadable(lazy(() => import('pages/events/index')));
const Policies = Loadable(lazy(() => import('pages/policies/index')));
const News = Loadable(lazy(() => import('pages/news/index')));
const Tasks = Loadable(lazy(() => import('pages/tasks/index')));
const Projects = Loadable(lazy(() => import('pages/projects/index')));
const Project = Loadable(lazy(() => import('pages/projects/project')));
const Profile = Loadable(lazy(() => import('pages/Profile/index')));
const Myvoice = Loadable(lazy(() => import('pages/myVoice/index')));
const Meetings = Loadable(lazy(() => import('pages/meeting/Index')));
const Meet = Loadable(lazy(() => import('pages/meeting/Meeting')));
const SelfStudy = Loadable(lazy(() => import('pages/selfStudy/Index')));
const Groups = Loadable(lazy(() => import('pages/groups/Index')));
const Group = Loadable(lazy(() => import('pages/groups/Group')));
const Library = Loadable(lazy(() => import('pages/library/Index')));
const Shared = Loadable(lazy(() => import('pages/shared/Index')));
const SingleView = Loadable(lazy(() => import('../components/SingleView')));
const SingleEvent = Loadable(lazy(() => import('pages/events/SingleEvent')));
const GetSocial = Loadable(lazy(() => import('pages/get-social/Index')));
const Role = Loadable(lazy(() => import('pages/RolePermission/Role/Index')));
const Permission = Loadable(lazy(() => import('pages/RolePermission/Permission/Index')));
const EmployeeActivity = Loadable(lazy(() => import('pages/employee-activity/Index')));
const ProjectManagement = Loadable(lazy(() => import('pages/project-management/Index')));
const Messenger = Loadable(lazy(() => import('pages/messenger/Index')));
const Organization = Loadable(lazy(() => import('pages/organization/analytics/Index')));
const Subscription = Loadable(lazy(() => import('pages/subscription/Index')));
const ClientSubscription = Loadable(lazy(() => import('pages/subscription/client/Index')));
const Subscribed = Loadable(lazy(() => import('pages/subscription/Subscribed')));
const Department = Loadable(lazy(() => import('pages/department/Index')));
const DepartmentAnalytics = Loadable(lazy(() => import('pages/organization/analytics/Department')));
const UserAnalytics = Loadable(lazy(() => import('pages/organization/analytics/User')));
const Notice = Loadable(lazy(() => import('pages/notice/Index')));
const Reward = Loadable(lazy(() => import('pages/reward/Index')));
const Movement = Loadable(lazy(() => import('pages/movement/Index')));
const CompanyMovements = Loadable(lazy(() => import('pages/movement/Company')));
const MeetingRecords = Loadable(lazy(() => import('pages/meeting/MeetingRecords')));
const FootNotes = Loadable(lazy(() => import('pages/foot-notes/Index')));
const Goals = Loadable(lazy(() => import('pages/golas/index')));
import { check_access_token } from 'lib/auth';
import { element } from 'prop-types';
import RegisteredUsers from 'pages/ registered-users/index';
import TermsAndConditions from 'pages/term-and-condition/TermsAndConditions';
import Policy from 'pages/policy/Policy';

import Privacy from 'pages/privacy/Privacy';
import OrganizationAnalytics from 'pages/organization-analytics/index';
import SelfAnalytics from 'pages/self-analytics/index';
import SupervisorAnalytics from 'pages/supervisor-analytics/index';
import RefundPolicy from 'pages/refundPolicy/index';
import ViewProfile from 'pages/Profile/ViewProfile';
import DepartmentDivision from 'pages/departmentdivision/Index';

// ==============================|| MAIN ROUTING ||============================== //
let DashRoutes = {};
export let LandingRoutes = {};
if (check_access_token) {
    DashRoutes = {
        path: '/',
        element: <Login />
    };
} else {
    LandingRoutes = {
        path: '/',
        element: <FrontLayout />,
        children: [
            {
                path: 'leadersvoice',
                element: <Leadersvoice />
            },
            {
                path: 'news-events/events',
                element: <Events />
            },
            {
                path: 'news-events/news',
                element: <News />
            },
            {
                path: 'policy-procedure',
                element: <Policies />
            },
            {
                path: 'tasks',
                element: <Tasks />
            },
            {
                path: 'projects',
                element: <Projects />
            },
            {
                path: 'projects/:projectId',
                element: <Project />
            },
            {
                path: 'profile/:params',
                element: <Profile />
            },
            {
                path: 'view-profile/:params',
                element: <ViewProfile />
            },
            {
                path: 'voice/',
                element: <Myvoice />
            },
            {
                path: 'meeting/',
                element: <Meetings />
            },
            {
                path: 'self-study/',
                element: <SelfStudy />
            },
            {
                path: 'group/',
                element: <Groups />
            },
            {
                path: 'group/:groupId',
                element: <Group />
            },
            {
                path: 'company-library/',
                element: <Library />
            },
            {
                path: 'shared/',
                element: <Shared />
            },
            {
                path: 'single-view/:params',
                element: <SingleView />
            },
            {
                path: 'meeting/:meetingId',
                element: <Meet />
            },
            {
                path: 'event/:eventId',
                element: <SingleEvent />
            },
            {
                path: 'get-social',
                element: <GetSocial />
            },
            {
                path: 'messenger',
                element: <Messenger />
            },
            {
                path: 'reward-recognition',
                element: <Reward />
            },
            {
                path: 'movement-visit',
                element: <Movement />
            },
            {
                path: 'meeting-records',
                element: <MeetingRecords />
            },
            {
                path: 'goals',
                element: <Goals />
            },
            {
                path: 'registered-users',
                element: <RegisteredUsers />
            },
            {
                path: 'self-analytics',
                element: <SelfAnalytics />
            },
            {
                path: 'supervisor-analytics',
                element: <SupervisorAnalytics />
            },
            {
                path: 'organization-analytics',
                element: <OrganizationAnalytics />
            },
            {
                path: 'terms-and-conditions',
                element: <TermsAndConditions />
            },
            {
                path: 'policy',
                element: <Policy />
            },
            {
                path: 'refund-policy',
                element: <RefundPolicy />
            },
            {
                path: 'privacy-policy',
                element: <Privacy />
            }
        ]
    };
    DashRoutes = {
        path: '/dashboard',
        element: <MainLayout />,
        children: [
            {
                path: '',
                element: <DashboardDefault />
            },
            {
                path: 'divisions',
                element: <Divisions />
            },
            {
                path: 'districts',
                element: <Districts />
            },
            {
                path: 'locations',
                element: <Locations />
            },
            {
                path: 'companies',
                element: <Companies />
            },
            {
                path: 'employees',
                element: <Employees />
            },
            {
                path: 'roles',
                element: <Role />
            },
            {
                path: 'permissions',
                element: <Permission />
            },
            {
                path: 'sample-page',
                element: <SamplePage />
            },

            {
                path: 'shadow',
                element: <Shadow />
            },
            {
                path: 'typography',
                element: <Typography />
            },
            {
                path: 'icons/ant',
                element: <AntIcons />
            },
            {
                path: 'employee-activity',
                element: <EmployeeActivity />
            },
            {
                path: 'organization-analytics',
                element: <Organization />
            },
            {
                path: 'project-management',
                element: <ProjectManagement />
            },
            {
                path: 'subscription',
                element: <Subscription />
            },
            {
                path: 'client-subscription',
                element: <ClientSubscription />
            },
            {
                path: 'clients-under-projects',
                element: <Subscribed />
            },
            {
                path: 'department',
                element: <Department />
            },
            {
                path: 'department-division',
                element: <DepartmentDivision />
            },
            {
                path: 'notice',
                element: <Notice />
            },
            {
                path: 'deparment-analytics',
                element: <DepartmentAnalytics />
            },
            {
                path: 'user-analytics',
                element: <UserAnalytics />
            },
            {
                path: 'movements',
                element: <CompanyMovements />
            },
            {
                path: 'footnotes',
                element: <FootNotes />
            }
        ]
    };
}

export default DashRoutes;
