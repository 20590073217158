import React from 'react';
import { Card, Typography, Box, Button, Badge } from '@mui/material';
import avatar from '../../assets/images/users/avatar-2.png';
import { Avatar } from '@mui/material';
import { convertDate } from 'Helpers';
const ViewProfileModal = ({ setViewProfile, profileData }) => {
    console.log(profileData?.createdAt);
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100vw', height: '100%' }}
            onClick={() => setViewProfile(false)}
        >
            <Card
                sx={{ p: 4, position: 'relative', mx: { xs: '10px' }, minWidth: { md: '400px', xs: 'auto' } }}
                onClick={(e) => e.stopPropagation()}
            >
                <Badge
                    color="primary"
                    badgeContent={'X'}
                    className="cursor-pointer"
                    onClick={() => {
                        setViewProfile(false);
                    }}
                    sx={{ position: 'absolute', right: '30px' }}
                ></Badge>
                <Box sx={{ maxHeight: '60vh', mb: '20px' }}>
                    <Box>
                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ flexDirection: 'column', mt: '24px' }}>
                            <Avatar
                                src={
                                    profileData.profile_image && profileData.profile_image != ''
                                        ? profileData.profile_image
                                        : profileData.first_name
                                }
                                alt={profileData.first_name}
                                sx={{
                                    width: '136px',
                                    height: '136px',
                                    objectFit: 'cover',
                                    borderRadius: '50%',
                                    marginBottom: '20px'
                                }}
                            />
                            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.87)' }}>
                                {profileData.first_name} {profileData.last_name}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', color: '#1E8874' }}>{profileData?.role?.role_type}</Typography>
                            {profileData?.createdAt && (
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mt: '12px'
                                    }}
                                >
                                    <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)', mr: '5px' }}>Joined at</Typography>
                                    {`${convertDate(profileData?.createdAt)}`}
                                </Typography>
                            )}

                            {/* <Box mt={2}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        background: 'rgba(0, 0, 0, 0.05)',
                                        color: 'black',
                                        '&:hover': {
                                            background: 'rgba(0, 0, 0, 0.1)'
                                        }
                                    }}
                                >
                                    Send Message
                                </Button>
                            </Box> */}
                        </Box>
                        {/* about */}
                        <Box mt={2} sx={{ maxHeight: '30vh' }} className="overflw hover-scroll">
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    About
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.about ?? 'N/A'}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    Phone
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.phone_no ?? 'N/A'}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    Email
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.email ?? 'N/A'}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    Hobby
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.hobby ?? 'N/A'}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    Present Address
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.street_address ?? 'N/A'}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    Permanent Address
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.permanent_address ?? 'N/A'}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    Emergent Contact number
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.emergency_contact ?? 'N/A'}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    Blood Group
                                </Typography>
                                <Typography variant="p" sx={{ fontSize: '16px', opacity: '60%' }}>
                                    {profileData.blood_group ?? 'N/A'}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default ViewProfileModal;
