/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateVoice = ({ text, setText, initial, setInitial = false }) => {
    useEffect(() => {
        if (text == 'Type Here....') {
            setText('');
        }
    }, []);
    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];
    const modules = {
        toolbar: [
            [{ header: [1, 2, 3] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            ['blockquote', 'code-block'],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ['link', 'image'],
            ['clean']
        ]
    };
    return (
        <>
            <ReactQuill theme="snow" value={text} onChange={setText} formats={formats} modules={modules} />
        </>
    );
};

export default CreateVoice;
