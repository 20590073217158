/* eslint-disable prettier/prettier */
// project import
import Organization from "./organization";
import Profile from "./profile";
import People from "./people";
// ==============================|| MENU ITEMS ||============================== //

let menuItems = {
};

let items=[People,Organization];
if(JSON.parse(window.localStorage.getItem('authUser')) && JSON.parse(window.localStorage.getItem('authUser')).username){
    items.push(Profile);
}
menuItems={items: items};
export default menuItems;
