/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import axios from 'lib/axios';
import HasPermission from 'utils/HasPermission';
const About = () => {
    const [userData, setUserDate] = useState();
    const [sahredMedia, setSharedMedia] = useState();
    const [offest, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [showMore, setShowMore] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    async function sahredFiles() {
        await axios
            .post('/shared', {
                offset: offest,
                limit: limit
            })
            .then(({ data }) => {
                console.log(data);
                if (data.success) {
                    setSharedMedia(data.shared);
                }
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        HasPermission(setIsAdmin, 'User', 'create', 'create_user');
        axios
            .post(`/users/user`, {
                id: JSON.parse(window.localStorage.getItem('authUser')).user_id.toString()
            })
            .then(({ data }) => {
                setUserDate(data.data);
            })
            .catch((err) => console.log(err));
        sahredFiles();
    }, []);
    return (
        <>
            {userData ? (
                <Grid container sm={12} display="flex" direction="column" spacing={2} sx={{ paddingRight: '1.5rem', paddingY: '1.5rem' }}>
                    <Grid item sm={12}>
                        <Grid container sm={12} display="flex" direction="column">
                            <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                <Typography sx={{ fontWeight: '700' }} variant="h6">
                                    {userData?.role?.role_type ?? 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                <Typography sx={{ opacity: '60%' }}>{userData?.role?.description ?? 'N/A'}</Typography>
                            </Grid>
                            <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                <Typography sx={{ fontWeight: '700' }} variant="h6">
                                    Short Profile
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                {userData.about ? (
                                    <Typography>
                                        {userData.about.length > 50 && showMore ? (
                                            <>
                                                <Typography sx={{ opacity: '60%' }}>{userData.about.substring(0, 50)}</Typography>
                                                <Typography
                                                    color="primary"
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        setShowMore((prev) => !prev);
                                                    }}
                                                >
                                                    Show More
                                                </Typography>
                                            </>
                                        ) : (
                                            <Typography sx={{ opacity: '60%' }}>{userData?.about ?? 'N/A'}</Typography>
                                        )}
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container sm={12} display="flex" direction="column">
                            <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                <Typography sx={{ fontWeight: '700' }} variant="h6">
                                    Joined
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography sx={{ opacity: '60%' }}>{`${new Date(userData?.createdAt).toString().split(' ')[1]} ${
                                    new Date(userData?.createdAt).toString().split(' ')[2]
                                } , ${new Date(userData?.createdAt).toString().split(' ')[3]} 
                                            `}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container sm={12} display="flex" direction="column">
                            <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                <Typography sx={{ fontWeight: '700' }} variant="h6">
                                    Email
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography sx={{ opacity: '60%' }}>{userData?.email ?? 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* {userData?.phone ? ( */}
                    <Grid item sm={12}>
                        <Grid container sm={12} display="flex" direction="column">
                            <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                <Typography sx={{ fontWeight: '700' }} variant="h6">
                                    Phone
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography sx={{ opacity: '60%' }}>{userData?.phone ?? 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        <Grid container sm={12} display="flex" direction="column">
                            <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                <Typography sx={{ fontWeight: '700' }} variant="h6">
                                    Hobby
                                </Typography>
                            </Grid>
                            <Grid item sm={12}>
                                <Typography sx={{ opacity: '60%' }}>{userData?.hobby ?? 'N/A'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* ) : (
                        <></>
                    )} */}
                    {isAdmin || userData.user_id === JSON.parse(window.localStorage.getItem('authUser')).user_id ? (
                        <>
                            <Grid item sm={12}>
                                <Grid container sm={12} display="flex" direction="column">
                                    <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                        <Typography sx={{ fontWeight: '700' }} variant="h6">
                                            Present Address
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography sx={{ opacity: '60%' }}>{userData?.street_address ?? 'N/A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container sm={12} display="flex" direction="column">
                                    <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                        <Typography sx={{ fontWeight: '700' }} variant="h6">
                                            Permanent Address
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography sx={{ opacity: '60%' }}>{userData?.permanent_address ?? 'N/A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container sm={12} display="flex" direction="column">
                                    <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                        <Typography sx={{ fontWeight: '700' }} variant="h6">
                                            Emergency Contact Number
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography sx={{ opacity: '60%' }}>{userData?.emergency_contact ?? 'N/A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={12}>
                                <Grid container sm={12} display="flex" direction="column">
                                    <Grid item sm={12} sx={{ paddingBottom: '0px' }}>
                                        <Typography sx={{ fontWeight: '700' }} variant="h6">
                                            Blood Group
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <Typography sx={{ opacity: '60%' }}>{userData?.blood_group ?? 'N/A'}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}
                </Grid>
            ) : (
                <></>
            )}
            {sahredMedia && sahredMedia.length > 0 ? (
                <Grid
                    sm={12}
                    container
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ paddingY: '1.5rem' }}
                    display="flex"
                    alignItems="center"
                >
                    <Grid item sm={6}>
                        <Typography sx={{ fontWeight: '700' }}>Shared files</Typography>
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        display="flex"
                        alignItems="center"
                        className="cursor-pointer"
                        justifyContent="end"
                        onClick={() => {
                            location.replace(`${window.location.origin}/shared`);
                        }}
                    >
                        <Typography sx={{ opacity: '60%' }}>View all</Typography>
                    </Grid>
                    <Grid container sm={12} spacing={1} sx={{ width: '100%', pl: '16px', mt: '10px' }}>
                        {sahredMedia.map((media) => {
                            return (
                                <Grid
                                    item
                                    xs={4}
                                    sm={4}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() => {
                                        setSelected(media);
                                        setOpenViewer(true);
                                    }}
                                >
                                    {media.type == 'image' ? (
                                        <img
                                            className="img-fit"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: '4px'
                                            }}
                                            src={`${process.env.REACT_APP_BACKEND_URL}/${media.url.replace('./public/', '')}`}
                                            alt="media"
                                        />
                                    ) : (
                                        <video
                                            className="img-fit"
                                            width="100%"
                                            height="100%"
                                            controls
                                            src={`${process.env.REACT_APP_BACKEND_URL}/${media.url.replace('./public/', '')}`}
                                        />
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};

export default About;
