/* eslint-disable prettier/prettier */
const Employees = {
    id: 'employees',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'employees',
            title: 'User List',
            type: 'item',
            url: '/dashboard/employees',
            breadcrumbs:false
            // target: true //in order to open in new tab
        },
        {
            id: 'employeeactivity',
            title: 'Employee Activities',
            type: 'item',
            url: '/dashboard/employee-activity',
            breadcrumbs:false
            // target: true //in order to open in new tab
        }
    ]
};

export default Employees;
 