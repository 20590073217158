// assets
import { UsergroupAddOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UsergroupAddOutlined,
    MedicineBoxOutlined
};
const children = [
    {
        id: 'func',
        title: 'Functions',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: [
            {
                id: 'department',
                title: 'Departments',
                type: 'item',
                url: '/dashboard/department',
                breadcrumbs: false
            },
            {
                id: 'departmentDivision',
                title: 'Department Division',
                type: 'item',
                url: '/dashboard/department-division',
                breadcrumbs: false
            },
            {
                id: 'role',
                title: 'Job Roles',
                type: 'item',
                url: '/dashboard/roles',
                breadcrumbs: false
            },
            {
                id: 'divisions',
                title: 'Divisions',
                type: 'item',
                url: '/dashboard/divisions',
                breadcrumbs: false
            },
            {
                id: 'districts',
                title: 'Districts',
                type: 'item',
                url: '/dashboard/districts',
                breadcrumbs: false
            },
            {
                id: 'locations',
                title: 'Locations',
                type: 'item',
                url: '/dashboard/locations',
                breadcrumbs: false
            },
            {
                id: 'permission',
                title: 'Permission List',
                type: 'item',
                url: '/dashboard/permissions',
                breadcrumbs: false
            }
        ]
    }
];
const Functions = {
    id: 'functions',
    title: 'Functions',
    type: 'group',
    icon: icons.MedicineBoxOutlined,
    children: children
};

export default Functions;
