// import React from 'react';
// import { Box, Card } from '@mui/material';
// import Analytics from 'components/profile/Analytics';

// const SelfAnalytics = () => {
//     return (
//         <Box
//             sx={{
//                 maxHeight: 'calc(100vh - 7.5rem)',
//                 overflowY: 'auto'
//             }}
//         >
//             <Card>
//                 <Analytics />
//             </Card>
//         </Box>
//     );
// };

// export default SelfAnalytics;
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Posts from 'pages/leaders-voice/Posts';
import Modal from '@mui/material/Modal';
import PostViewer from 'components/modals/PostViewer';

import DateFilter from 'components/modals/DateFilter';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/material';
import AnalyticsChart from 'components/profile/AnalyticsChart';
import EngagementTable from 'components/profile/EngagementTable';
import ActivitySummary from 'components/profile/ActivitySummary';
const SelfAnalytics = ({ member_id }) => {
    const [selected, setSelected] = useState();
    const [oepnViewer, setOpenViewer] = useState(false);
    const [completedTask, setCompletedTask] = useState(0);
    const [onProgressTask, setOnProgressTask] = useState(0);
    const [todoTask, setTodoTask] = useState(0);
    const [onGoingProject, setOnGoingProject] = useState(0);
    const [completedProject, setCompletedProject] = useState(0);
    const [attendedEvent, setAttendedEvent] = useState(0);
    const [notAttendedEvent, setNotAttendedEvent] = useState(0);
    const [attendedMeeting, setAttendingMeeting] = useState(0);
    const [notAttendedMeeting, setNotAttendedMeeting] = useState(0);
    const [successStory, setSucessStory] = useState(0);
    const [skills, setSkills] = useState(0);
    const [voice, setVoice] = useState(0);
    const [policyViewed, setPolicyViewed] = useState(0);
    const [policyLikes, setPolicyLikes] = useState(0);
    const [policyComment, setPolicyComment] = useState(0);
    const [series, setSeries] = useState();
    const [engagements, setEngagements] = useState([]);
    const [monthRange, setMonthRange] = useState(0);
    const [body, setBody] = useState({});
    useEffect(() => {
        resetAll();
        if (member_id) {
            setBody({ member_id: member_id });
        }
        if (monthRange != 0) {
            setBody(() => {
                return member_id ? { member_id: member_id, month: monthRange } : { month: monthRange };
            });
        }

        fetchTaskProjectSummary();
        fetchEventSummary();
        fetchMeetingSummary();
        fetchStoriesSummary();
        fetchSkillsSummary();
        fetchPolicySummary();

        fetchEngagementSummary();
    }, [monthRange, member_id]);
    function resetAll() {
        setCompletedTask(0);
        setOnProgressTask(0);
        setTodoTask(0);
        setOnGoingProject(0);
        setCompletedProject(0);
        setAttendedEvent(0);
        setNotAttendedEvent(0);
        setAttendingMeeting(0);
        setNotAttendedMeeting(0);
        setSucessStory(0);
        setSkills(0);
        setVoice(0);
        setPolicyViewed(0);
        setPolicyLikes(0);
        setPolicyComment(0);
        setSeries();
        setEngagements([]);
    }
    async function fetchTaskProjectSummary() {
        await axios
            .post(`/activities/personal-summary/project-task`, body)
            .then(({ data }) => {
                data.summary?.tasks.map((task) => {
                    if (task.task_status_id == 1) {
                        setTodoTask((prev) => {
                            return prev + parseInt(task.count);
                        });
                    } else if (task.task_status_id == 2) {
                        setOnProgressTask((prev) => {
                            return prev + parseInt(task.count);
                        });
                    } else if (task.task_status_id == 3) {
                        setCompletedTask((prev) => {
                            return prev + parseInt(task.count);
                        });
                    }
                });
                data.summary?.projects?.map((project) => {
                    if (project.project_status == 'pending') {
                        setOnGoingProject((prev) => {
                            return prev + parseInt(project.count);
                        });
                    } else if (project.project_status == 'completed') {
                        setCompletedProject((prev) => {
                            return prev + parseInt(project.count);
                        });
                    }
                });
            })
            .catch((err) => console.log(err));
    }
    async function fetchEventSummary() {
        await axios
            .post(`/activities/personal-summary/events`, body)
            .then(({ data }) => {
                data.summary?.map((item) => {
                    if (item.rsvp == 'yes') {
                        setAttendedEvent((prev) => {
                            return prev + parseInt(item.count);
                        });
                    } else if (item.rsvp == 'no') {
                        setNotAttendedEvent((prev) => {
                            return prev + parseInt(item.count);
                        });
                    }
                });
            })
            .catch((err) => console.log(err));
    }
    async function fetchMeetingSummary() {
        await axios
            .post(`/activities/personal-summary/meeting`, body)
            .then(({ data }) => {
                if (data.success) {
                    setAttendingMeeting(parseInt(data.summary.attended));
                    setNotAttendedMeeting(parseInt(data.summary.upcoming));
                }
            })
            .catch((err) => console.log(err));
    }
    async function fetchStoriesSummary() {
        await axios
            .post(`/activities/personal-summary/stories`, body)
            .then(({ data }) => {
                if (data.success) {
                    setSucessStory(parseInt(data.summary.success[0].count));
                    setVoice(parseInt(data.summary.voice[0].count));
                }
            })
            .catch((err) => console.log(err));
    }
    async function fetchSkillsSummary() {
        await axios
            .post('/activities/personal-summary/skills', body)
            .then(({ data }) => {
                if (data.success) {
                    setSkills(parseInt(data.summary[0].count));
                }
            })
            .catch((err) => console.log(err));
    }
    async function fetchPolicySummary() {
        await axios
            .post('/activities/personal-summary/policy', body)
            .then(({ data }) => {
                if (data.success) {
                    setPolicyViewed(parseInt(data.summary.commentscount[0].count));
                    setPolicyLikes(parseInt(data.summary.likescount[0].count));
                    setPolicyComment(parseInt(data.summary.viewd[0].count));
                }
            })
            .catch((err) => console.log(err));
    }
    async function fetchEngagementSummary() {
        await axios
            .post('/activities/personal-summary/engagement', body)
            .then(({ data }) => {
                if (data.success) {
                    if (data.summary) {
                        let array = [];
                        array.push({ id: 'tasks', name: 'Tasks', value: parseInt(data.summary.tasks[0].count) });
                        array.push({ id: 'project', name: 'Project', value: parseInt(data.summary.project[0].count) });
                        array.push({
                            id: 'success_stories',
                            name: 'Success Stories',
                            value: parseInt(data.summary.success_stories[0].count)
                        });
                        array.push({ id: 'event', name: 'Event', value: parseInt(data.summary.event[0].count) });
                        array.push({ id: 'meeting', name: 'Meeting', value: parseInt(data.summary.meeting[0].count) });
                        array.push({ id: 'knowledge', name: 'Knowledge & Skills', value: parseInt(data.summary.knowledge[0].count) });
                        array.push({ id: 'policy', name: 'Policy & Procedure', value: parseInt(data.summary.policy[0].count) });
                        array.push({ id: 'voice', name: 'Voice', value: parseInt(data.summary.voice[0].count) });
                        setEngagements(() => {
                            return array;
                        });
                        setSeries(() => {
                            return [
                                {
                                    name: 'Engagements',
                                    data: [
                                        parseInt(data.summary.tasks[0].count),
                                        parseInt(data.summary.project[0].count),
                                        parseInt(data.summary.success_stories[0].count),
                                        parseInt(data.summary.event[0].count),
                                        parseInt(data.summary.meeting[0].count),
                                        parseInt(data.summary.knowledge[0].count),
                                        parseInt(data.summary.policy[0].count),
                                        parseInt(data.summary.voice[0].count)
                                    ]
                                }
                            ];
                        });
                    }
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <Card
            sx={{
                maxHeight: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <Grid container sm={12}>
                <Grid item sm={12} sx={{ padding: '2rem', width: '100%' }}>
                    <Grid container sm={12} display="flex" alignItems="center" justifyContent="space-between" sx={{ marginY: '1rem' }}>
                        <Grid item sm={9}>
                            <Typography variant="h4" color="primary" sx={{ fontWeight: '600' }}>
                                Analytics
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            sm={3}
                            onClick={(e) => {
                                setOpenViewer(!oepnViewer);
                            }}
                        >
                            <FormControl>
                                <Select labelId="demo-simple-select-label" value={monthRange} sx={{ pointerEvents: 'none' }}>
                                    <MenuItem value={0}>
                                        <Typography>{`${new Date().toString().substring(3, 7)},${new Date()
                                            .toString()
                                            .substring(10, 16)}`}</Typography>
                                    </MenuItem>
                                    <MenuItem value={1}>
                                        <Typography>Last Month</Typography>
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        <Typography>Last 3 Month</Typography>
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        <Typography>Last 6 Month</Typography>
                                    </MenuItem>
                                    <MenuItem value={12}>
                                        <Typography>Last 1 Year</Typography>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* ------Analytics------- */}
                    <Box display="flex" spacing={2} sx={{ gap: '10px', flexDirection: { md: 'row', xs: 'column' }, width: '100%' }}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            {/* ---1-- */}
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1.5rem',
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            {completedTask}
                                        </Typography>
                                    </Box>

                                    <Box display="flex" direction="column" sx={{ padding: '0px' }}>
                                        <Box>
                                            <Typography color="white">TASKS</Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="white" sx={{ fontWeight: '500' }}>
                                                COMPLETED
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Box display="flex" alignItems="center">
                                            <Box>
                                                <Box display="flex" spacing={0} direction="column">
                                                    <Box>
                                                        <Typography color="white">{onProgressTask}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography color="white" sx={{ opacity: '80%' }}>
                                                            On Progress
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box container sm={12} display="flex" spacing={0} direction="column">
                                                    <Box>
                                                        <Typography color="white">{todoTask}</Typography>
                                                    </Box>
                                                    <Grid>
                                                        <Typography color="white" sx={{ opacity: '80%' }}>
                                                            To Do
                                                        </Typography>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    sx={{
                                        flexDirection: 'column',
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1.5rem',
                                        width: '100%'
                                    }}
                                    spacing={0}
                                >
                                    <Box item sm={12} sx={{ height: '3.5rem' }}>
                                        <Box container sm={12}>
                                            <Box item sm={12}>
                                                <Typography variant="h5" sx={{ fontWeight: '600' }} color="white">
                                                    Projects
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box item sm={12}>
                                        <Box container sm={12} display="flex" alignItems="center">
                                            <Box item sm={7}>
                                                <Box container sm={12} display="flex" spacing={0} direction="column">
                                                    <Box item sm={12}>
                                                        <Typography color="white">{onGoingProject}</Typography>
                                                    </Box>
                                                    <Box item sm={12}>
                                                        <Typography color="white" sx={{ opacity: '80%' }}>
                                                            On going
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box item sm={5}>
                                                <Box container sm={12} display="flex" spacing={0} direction="column">
                                                    <Box item sm={12}>
                                                        <Typography color="white">{completedProject}</Typography>
                                                    </Box>
                                                    <Box item sm={12}>
                                                        <Typography color="white" sx={{ opacity: '80%' }}>
                                                            Completed
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {/* ---2-- */}
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem'
                                    }}
                                    spacing={0}
                                    alignItems="center"
                                >
                                    <Grid item sm={4} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            {successStory}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={8}>
                                        <Grid container sm={12} display="flex" alignItems="center" sx={{ paddingX: '1.5rem' }}>
                                            <Grid item sm={12}>
                                                <Typography color="white">submitted</Typography>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Typography color="white" sx={{ fontWeight: '500' }}>
                                                    SUCCWESS STORIES
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>

                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem'
                                    }}
                                    spacing={0}
                                    alignItems="center"
                                >
                                    <Box item sm={4} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            {skills}
                                        </Typography>
                                    </Box>
                                    <Box item sm={8}>
                                        <Box container sm={12} display="flex" alignItems="center">
                                            <Box item sm={12}>
                                                <Typography color="white">Added</Typography>
                                            </Box>
                                            <Box item sm={12}>
                                                <Typography color="white" sx={{ fontWeight: '500' }}>
                                                    KNOWLEDGE & SKILLS
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {/* ---3- */}

                            <Box
                                display="flex"
                                direction="column"
                                sx={{
                                    background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                    borderRadius: '5px',
                                    padding: '1.5rem'
                                }}
                                spacing={1}
                            >
                                <Box>
                                    <Box>
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            Policy & Procedure
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box display="flex" alignItems="center">
                                        <Box display="flex" direction="column">
                                            <Box>
                                                <Typography color="white">{policyViewed}</Typography>
                                            </Box>
                                            <Box item sm={12}>
                                                <Typography color="white" sx={{ opacity: '80%' }}>
                                                    Viewed
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box item sm={2}>
                                            <Box container sm={12} display="flex" spacing={0} direction="column">
                                                <Box item sm={12}>
                                                    <Typography color="white">{policyLikes}</Typography>
                                                </Box>
                                                <Box item sm={12}>
                                                    <Typography color="white" sx={{ opacity: '80%' }}>
                                                        Liked
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box item sm={4}>
                                            <Box container sm={12} display="flex" spacing={0} direction="column">
                                                <Box item sm={12}>
                                                    <Typography color="white">{policyComment}</Typography>
                                                </Box>
                                                <Box item sm={12}>
                                                    <Typography color="white" sx={{ opacity: '80%' }}>
                                                        Commented
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%' }}>
                            <Box display="flex" sx={{ flexDirection: 'column', gap: '10px', width: '100%' }}>
                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        height: '100%',
                                        flexDirection: 'column',
                                        width: '100%'
                                    }}
                                    spacing={0}
                                >
                                    <Box>
                                        <Box>
                                            <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                                {attendedEvent}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box display="flex" direction="column" sx={{ padding: '0px' }}>
                                                <Box item sm={12}>
                                                    <Typography color="white">Attended</Typography>
                                                </Box>
                                                <Box item sm={12}>
                                                    <Typography color="white" sx={{ fontWeight: '500' }}>
                                                        EVENTS
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box item sm={12}>
                                        <Box container sm={12} display="flex" alignItems="center">
                                            <Box item sm={8}>
                                                <Typography color="red">Not Attended</Typography>
                                            </Box>
                                            <Box>
                                                <Typography color="white" sx={{ fontWeight: '500' }}>
                                                    {notAttendedEvent}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        height: '100%',
                                        flexDirection: 'column'
                                    }}
                                    spacing={0}
                                >
                                    <Box item sm={12}>
                                        <Box container sm={12}>
                                            <Box item sm={4}>
                                                <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                                    {attendedMeeting}
                                                </Typography>
                                            </Box>
                                            <Box item sm={8}>
                                                <Box
                                                    container
                                                    sm={12}
                                                    spacing={0}
                                                    display="flex"
                                                    direction="column"
                                                    sx={{ padding: '0px' }}
                                                >
                                                    <Box item sm={12}>
                                                        <Typography color="white">Attended</Typography>
                                                    </Box>
                                                    <Box item sm={12}>
                                                        <Typography color="white" sx={{ fontWeight: '500' }}>
                                                            MEETINGS
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box item sm={12}>
                                        <Box container sm={12} display="flex" alignItems="center">
                                            <Box item sm={8}>
                                                <Typography color="#FF9820">Upcoming</Typography>
                                            </Box>
                                            <Box item sm={4}>
                                                <Typography color="white" sx={{ fontWeight: '500' }}>
                                                    {notAttendedMeeting}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        height: '100%',
                                        flexDirection: 'column'
                                    }}
                                    alignItems="center"
                                >
                                    <Box item sm={4} display="flex" alignItems="center" justifyContent="center">
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            {voice}
                                        </Typography>
                                    </Box>
                                    <Box item sm={8}>
                                        <Box container sm={12} display="flex" alignItems="center">
                                            <Box item sm={12}>
                                                <Typography color="white">Sumitted</Typography>
                                            </Box>
                                            <Box item sm={12}>
                                                <Typography color="white" sx={{ fontWeight: '500' }}>
                                                    VOICE
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {/* ----------- */}
                    <ActivitySummary />
                    {/* --------- */}
                    <Grid container sm={12} sx={{ marginY: '1rem' }}>
                        <Grid item sm={12} sx={{ marginY: '1rem' }}>
                            <Typography variant="h4" color="primary">
                                Engagement Analytics
                            </Typography>
                        </Grid>
                        {series && (
                            <Grid item sm={12} sx={{ marginY: '1rem' }}>
                                <AnalyticsChart series={series} color={`${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container sm={12} sx={{ marginY: '1rem' }}>
                        {engagements?.length > 0 ? (
                            <EngagementTable engagements={engagements} color={`${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`} />
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>

                {oepnViewer ? (
                    <Modal
                        keepMounted
                        open={oepnViewer}
                        onClose={setOpenViewer}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <Grid container display="flex" justifyContent="center" alignItems="end" sx={{ width: '100vw' }}>
                            <DateFilter setOpenViewer={setOpenViewer} setMonthRange={setMonthRange} />
                        </Grid>
                    </Modal>
                ) : (
                    <></>
                )}
            </Grid>
        </Card>
    );
};

export default SelfAnalytics;
