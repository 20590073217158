import DOMPurify from 'dompurify';
import { Link, InputLabel, OutlinedInput, Snackbar, useTheme, useMediaQuery } from '@mui/material';

export const urlify = (text) => {
    if (!text) {
        return text;
    }
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
        return '<a target="_target" href="' + url + '">' + url + '</a>';
    });
};
export const textToHtml = (text) => {
    return urlify(text);
};

export const htmlContent = (content, showMore = false, count, preRef, showLess = false) => {
    const sanitizedHtml = DOMPurify.sanitize(
        `${showMore ? textToHtml(content.substr(0, count)) : textToHtml(content)} ${
            showMore
                ? '<span id="showMoreButton" class="fs-14 MuiTypography-root MuiTypography-body1 css-qcwhce-MuiTypography-root cursor-pointer">...View More</span>'
                : showLess
                ? '<span id="showLessButton" class="fs-14 MuiTypography-root MuiTypography-body1 css-qcwhce-MuiTypography-root cursor-pointer">...View Less</span>'
                : ''
        }`
    );
    return (
        <pre
            ref={preRef}
            style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
            dangerouslySetInnerHTML={{
                __html: `${sanitizedHtml}`
            }}
        />
    );
};
export const processToUpper = (text) => {
    if (text) {
        return text
            .split('_')
            .map((item) => item.replace(item[0], item[0].toUpperCase()))
            .join(' ');
    } else {
        return text;
    }
};
export const timeDiffer = (now, date) => {
    var diffMs = now - date; // milliseconds between now & the given date
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes

    return diffDays > 0
        ? `${diffDays}d${diffHrs > 0 ? ` ${diffHrs}h` : ''} ago`
        : diffHrs > 0
        ? `${diffHrs} hour${diffHrs > 1 ? 's' : ''} ago`
        : `${diffMins} minute${diffMins > 1 ? 's' : ''} ago`;
};

export const convertDate = (dateString) => {
    // Split the date string into components (year, month, day, time)
    const parts = dateString.split('T');
    const dateParts = parts[0].split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are zero-indexed
    const day = parseInt(dateParts[2], 10);

    // Define month names for formatting
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    // Format the date string
    const formattedDate = `${day} ${monthNames[month]} ${year}`;

    return formattedDate;
};

export const calculateTimeDifference = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    const now = new Date();
    const diffInMilliseconds = now - createdAtDate;

    const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);

    if (diffInHours > 0) {
        return `${diffInHours} h`;
    } else {
        return `${diffInMinutes} m`;
    }
};

export const convertDateAndTime = (dateString, yearAndWeek = true) => {
    const dateObject = new Date(dateString);

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthsOfYear = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    const day = daysOfWeek[dateObject.getDay()];
    const date = dateObject.getDate();
    const month = monthsOfYear[dateObject.getMonth()];
    const year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    const formattedDate = yearAndWeek
        ? `${day}, ${month} ${date}, ${year}, ${hours}:${minutes} ${ampm}`
        : `${month} ${date}, ${hours}:${minutes} ${ampm}`;

    return formattedDate;
};
