/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { Box, List, Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import NavItem from '../Navigation/NavItem';
import SubNavItem from '../Navigation/SubNavItem';

const SubNavGroup = ({ menuItem }) => {
    const [collapse, setCollapse] = useState(true);
    return (
        <>
            <SubNavItem key={menuItem.id} item={menuItem} level={1} collapse={collapse} setCollapse={setCollapse} />
            <div className={collapse ? 'd-none' : 'collapse'}>
                {menuItem.children.map((subItem) => {
                    return <NavItem key={subItem.id} item={subItem} level={2} />;
                })}
            </div>
        </>
    );
};

export default SubNavGroup;
