// assets
import { UserOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import OrgIcon from '../assets/static_images/org.svg';
// icons
const icons = {
    UserOutlined,
    MedicineBoxOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
let analyticsOptions = [
    {
        id: 'selfAnalytics',
        title: 'Self Analytics',
        type: 'collapse',
        url: '/self-analytics',
        breadcrumbs: false
    },
    {
        id: 'supervisorAnalytics',
        title: 'Supervisor Analytics',
        type: 'collapse',
        url: '/supervisor-analytics',
        breadcrumbs: false
    },
    {
        id: 'organizationAnalytics',
        title: 'Organization Analytics',
        type: 'collapse',
        url: '/organization-analytics',
        breadcrumbs: false
    }
];
let children = [
    {
        id: 'My Profile',
        title: 'My Profile',
        type: 'item',
        url: '/profile/null',
        breadcrumbs: false
    },
    {
        id: 'My Group',
        title: 'My Group',
        type: 'item',
        url: '/group',
        breadcrumbs: false
    },
    {
        id: 'voice',
        title: 'My Voice',
        type: 'item',
        url: '/voice',
        breadcrumbs: false
    },
    {
        id: 'EngagementAnalytics',
        title: 'Engagement Analytics',
        type: 'collapse',
        url: '#',
        breadcrumbs: false,
        children: analyticsOptions
    }
];

const Profile = {
    id: `profiletab`,
    title: JSON.parse(window.localStorage.getItem('authUser'))?.username,
    type: 'group',
    icon: icons.UserOutlined,
    children: children
};

export default Profile;
