// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import '../src/styles/css/App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import NotifyCall from 'components/modals/NotifyCall';
import { ModalProvider } from 'context/ModalContext';

// import * as dotenv from 'dotenv';
// dotenv.config();
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
    <ModalProvider>
        <NotifyCall />
        <ThemeCustomization>
            <ScrollTop>
                <Routes />
            </ScrollTop>
        </ThemeCustomization>
    </ModalProvider>
);

export default App;
