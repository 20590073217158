/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Posts from 'pages/leaders-voice/Posts';
import Modal from '@mui/material/Modal';
import PostViewer from 'components/modals/PostViewer';
import DateFilter from 'components/modals/DateFilter';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import GraphChart from 'components/GraphChart';
import Graphs from 'pages/dashboard/Graphs';
import { Box, Paper } from '@mui/material';
const OrganizationAnalytics = ({ member_id }) => {
    const [selected, setSelected] = useState();
    const [oepnViewer, setOpenViewer] = useState(false);
    const [oepnViewer2, setOpenViewer2] = useState(false);
    const [completedTask, setCompletedTask] = useState(0);
    const [onProgressTask, setOnProgressTask] = useState(0);
    const [todoTask, setTodoTask] = useState(0);
    const [onGoingProject, setOnGoingProject] = useState(0);
    const [completedProject, setCompletedProject] = useState(0);
    const [attendedEvent, setAttendedEvent] = useState(0);
    const [notAttendedEvent, setNotAttendedEvent] = useState(0);
    const [attendedMeeting, setAttendingMeeting] = useState(0);
    const [notAttendedMeeting, setNotAttendedMeeting] = useState(0);
    const [successStory, setSucessStory] = useState(0);
    const [skills, setSkills] = useState(0);
    const [voice, setVoice] = useState(0);
    const [policyViewed, setPolicyViewed] = useState(0);
    const [policyLikes, setPolicyLikes] = useState(0);
    const [policyComment, setPolicyComment] = useState(0);
    const [engagements, setEngagements] = useState([]);
    const [monthRange, setMonthRange] = useState(0);
    const [monthRange2, setMonthRange2] = useState(0);
    const [body, setBody] = useState({});
    const [yAxisUser, setYAxisUser] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            // arr.push(Math.floor(Math.random() * 30) + 1);
            arr.push(0);
        }
        return [
            {
                name: 'New User',
                data: arr
            }
        ];
    });
    const [yAxisSuccessPost, setYAxisSuccessPost] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            arr.push(0);
        }
        return [
            {
                name: 'Posts',
                data: arr
            }
        ];
    });
    const [yAxisLeaderPost, setYAxisLeaderPost] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            arr.push(0);
        }
        return [
            {
                name: 'Leader Voice',
                data: arr
            }
        ];
    });
    const [yAxisNews, setYAxisNews] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            arr.push(0);
        }
        return [
            {
                name: 'News',
                data: arr
            }
        ];
    });
    const [yAxisTask, setYAxisTask] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            arr.push(0);
        }
        return [
            {
                name: 'Tasks',
                data: arr
            }
        ];
    });
    const [yAxisEvent, setYAxisEvent] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            arr.push(0);
        }
        return [
            {
                name: 'Events',
                data: arr
            }
        ];
    });
    const [yAxisMeeting, setYAxisMeeting] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            arr.push(0);
        }
        return [
            {
                name: 'Meetings',
                data: arr
            }
        ];
    });
    const [yAxisProject, setYAxisProject] = useState(() => {
        let arr = [];
        for (let i = 0; i < 30; i++) {
            arr.push(0);
        }
        return [
            {
                name: 'Projects',
                data: arr
            }
        ];
    });
    const [fetched, setFetched] = useState(false);
    async function fetchSummeries() {
        await axios
            .post(`/dashoard/summary`)
            .then(({ data }) => {
                if (data.success) {
                    setYAxisUser((prev) => {
                        data.summary.users.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.userpostcounts);
                        });
                        return prev;
                    });
                    setYAxisSuccessPost((prev) => {
                        data.summary.success_story.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.leadervoicepostcounts);
                        });
                        return prev;
                    });
                    setYAxisLeaderPost((prev) => {
                        data.summary.leader_voice.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.leadervoicepostcounts);
                        });
                        return prev;
                    });
                    setYAxisNews((prev) => {
                        data.summary.news.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.newscounts);
                        });
                        return prev;
                    });
                    setYAxisTask((prev) => {
                        data.summary.task.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.taskscounts);
                        });
                        return prev;
                    });
                    setYAxisEvent((prev) => {
                        data.summary.event.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.eventscounts);
                        });
                        return prev;
                    });
                    setYAxisMeeting((prev) => {
                        data.summary.meeting.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.meetingscounts);
                        });
                        return prev;
                    });
                    setYAxisProject((prev) => {
                        data.summary.project.map((item) => {
                            prev[0].data[parseInt(item.date.slice(-2))] = parseInt(item.projectscounts);
                        });
                        return prev;
                    });
                    setFetched(true);
                }
            })
            .catch((err) => console.log(err));
    }

    const [series, setSeries] = useState();
    const [series2, setSeries2] = useState();
    useEffect(() => {
        fetchSummary();
        fetchSummeries();
    }, []);
    // useEffect(() => {
    //     fetchSuccessStory();
    // }, [monthRange]);
    // useEffect(() => {
    //     fetchTasks();
    // }, [monthRange2]);

    async function fetchSummary() {
        await axios.post('/activities/activity/organization-summary').then(({ data }) => {
            if (data.success) {
                // setting taska
                data.summary.tasks.map((item) => {
                    if (item.status == 'todo') {
                        setTodoTask((prev) => {
                            return prev + parseInt(item.count);
                        });
                    } else if (item.status == 'completed') {
                        setCompletedTask((prev) => {
                            return prev + parseInt(item.count);
                        });
                    } else {
                        setOnProgressTask((prev) => {
                            return prev + parseInt(item.count);
                        });
                    }
                });
                // setting projects
                data.summary.projects.map((item) => {
                    if (item.status == 'pending') {
                        setOnGoingProject((prev) => {
                            return prev + parseInt(item.count);
                        });
                    } else if (item.status == 'completed') {
                        setCompletedProject((prev) => {
                            return prev + parseInt(item.count);
                        });
                    }
                });
                // setting policies
                data.summary.policies.map((item) => {
                    if (item.viewCount) {
                        setPolicyViewed((prev) => prev + parseInt(item.viewCount));
                    } else if (item.likeCount) {
                        setPolicyLikes((prev) => prev + parseInt(item.likeCount));
                    } else if (item.commentCount) {
                        setPolicyComment((prev) => prev + parseInt(item.commentCount));
                    }
                });
                // story setting
                if (data.summary.success_stories) {
                    setSucessStory((prev) => prev + parseInt(data.summary.success_stories.Count));
                }
                if (data.summary.skills) {
                    setSkills((prev) => prev + parseInt(data.summary.skills.Count));
                }
                // setting events
                if (data.summary.events.all) {
                    setAttendedEvent((prev) => prev + parseInt(data.summary.events.all.Count));
                } else if (data.summary.events.upcoming) {
                    setNotAttendedEvent((prev) => prev + parseInt(data.summary.events.upcoming.Count));
                }
                // setting Meetings
                if (data.summary.meetings.all) {
                    setAttendingMeeting((prev) => prev + parseInt(data.summary.meetings.all.Count));
                } else if (data.summary.meetings.upcoming) {
                    setNotAttendedMeeting((prev) => prev + parseInt(data.summary.meetings.upcoming.Count));
                }
                if (data.summary.voice) {
                    setVoice((prev) => prev + parseInt(data.summary.voice.Count));
                }
            }
        });
    }
    async function fetchSuccessStory() {
        await axios
            .post('/activities/activity/success-story-summary-by-day', {
                month: monthRange
            })
            .then(({ data }) => {
                if (data.success) {
                    let temp = [];
                    for (let i = 0; i < categories.length; i++) {
                        temp.push(i);
                    }
                    let newarr = [];
                    for (let i = 0; i < categories.length; i++) {
                        newarr.push(0);
                    }
                    data.summary.map((item) => {
                        if (temp.indexOf(item.day) > 0) {
                            newarr.splice(1, categories.indexOf(item.day), item.count);
                        }
                    });
                    setSeries(() => {
                        return [
                            {
                                name: 'stories',
                                data: newarr
                            }
                        ];
                    });
                }
            });
    }
    async function fetchTasks() {
        await axios
            .post('/activities/activity/organization-task-summary-by-day', {
                month: monthRange2
            })
            .then(({ data }) => {
                if (data.success) {
                    let temp = [];
                    for (let i = 0; i < categories.length; i++) {
                        temp.push(i);
                    }
                    let newarr = [];
                    for (let i = 0; i < categories.length; i++) {
                        newarr.push(0);
                    }
                    data.summary.map((item) => {
                        if (temp.indexOf(item.day) > 0) {
                            newarr.splice(1, categories.indexOf(item.day), item.count);
                        }
                    });
                    setSeries2(() => {
                        return [
                            {
                                name: 'tasks',
                                data: newarr
                            }
                        ];
                    });
                }
            });
    }
    return (
        <Card
            sx={{
                height: 'calc(100vh - 7.5rem)',
                overflowY: 'auto'
            }}
        >
            <Grid sm={12} container>
                <Grid sm={12} item sx={{ paddingX: { md: '2rem' } }}>
                    <Grid container sm={12} display="flex" alignItems="center" sx={{ marginY: '1rem' }}>
                        <Grid item sm={9}>
                            <Typography variant="h4" sx={{ fontWeight: '600' }}>
                                Analytics
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container columns={{ xs: 12 }} spacing={1}>
                        <Grid container xs={12} sm={6}>
                            {/* Tasks */}
                            <Grid item xs={12} sm={6} sx={{ width: '100%' }}>
                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1.5rem',
                                        flexDirection: 'column'
                                    }}
                                    spacing={0}
                                >
                                    <Box sx={{ paddingY: '1rem' }}>
                                        <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                            Tasks
                                        </Typography>
                                    </Box>

                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                    >
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                                COMPLETED
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="white" variant="h4" sx={{ fontWeight: '500' }}>
                                                {completedTask}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                    >
                                        <Box item sm={6}>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                                On Going
                                            </Typography>
                                        </Box>
                                        <Box item sm={6}>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                                {onProgressTask}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ paddingY: '.5rem' }}>
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                                To Do
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                                {todoTask}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* TOTAL */}
                            <Grid item xs={12} sm={6}>
                                <Box
                                    display="flex"
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1.5rem',
                                        flexDirection: 'column'
                                    }}
                                    spacing={0}
                                >
                                    <Box sx={{ paddingY: '1rem' }}>
                                        <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                            Projects
                                        </Typography>
                                    </Box>

                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                    >
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                                TOTAL
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="white" variant="h4" sx={{ fontWeight: '500' }}>
                                                {completedProject + onGoingProject}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{ paddingY: '.5rem', borderBottom: '1px solid #CBFDFD' }}
                                    >
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                                COMPLETED
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                                {completedProject}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ paddingY: '.5rem' }}>
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400', color: '#CBFDFD' }}>
                                                On Going
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '400' }}>
                                                {onGoingProject}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* Policy & Procedure */}
                        <Grid container xs={12} sm={6}>
                            <Grid item xs={12} sm={12}>
                                <Box
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Box sx={{ paddingY: '1rem' }}>
                                        <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                            Policy & Procedure
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={{ md: 5, xs: 2 }}
                                        sx={{ width: '100%', overflowX: 'scroll' }}
                                    >
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography variant="h6" color="white">
                                                {policyViewed}
                                            </Typography>
                                            <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                                Viewed
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography variant="h6" color="white">
                                                {policyLikes}
                                            </Typography>
                                            <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                                Liked
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '600' }}>
                                                {policyComment}
                                            </Typography>
                                            <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                                Commented
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* Events */}
                            <Grid item xs={12} sm={6}>
                                <Box
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Box sx={{ paddingY: '1rem' }}>
                                        <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                            Events
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={5}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography color="white" variant="h6" sx={{ fontWeight: '600' }}>
                                                {attendedEvent}
                                            </Typography>
                                            <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                                Attended
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography sx={{ opacity: '80%', color: 'red', fontWeight: '600' }}>
                                                {notAttendedEvent}
                                            </Typography>
                                            <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                                Absent
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* Meetings */}
                            <Grid xs={12} sm={6}>
                                <Box
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        flexDirection: 'column'
                                    }}
                                >
                                    <Box sx={{ paddingY: '1rem' }}>
                                        <Typography color="white" variant="h5" sx={{ fontWeight: '600' }}>
                                            Meetings
                                        </Typography>
                                    </Box>
                                    <Box display="flex" alignItems="center" gap={5}>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography color="white" variant="h6">
                                                {attendedMeeting}
                                            </Typography>
                                            <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                                Attended
                                            </Typography>
                                        </Box>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Typography variant="h6" sx={{ fontWeight: '600', color: 'red', opacity: '80%' }}>
                                                {notAttendedMeeting}
                                            </Typography>
                                            <Typography color="white" sx={{ opacity: '80%', color: '#CBFDFD' }}>
                                                Missed
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container xs={12} sm={12}>
                            {/* Submitted */}
                            <Grid xs={12} sm={4}>
                                <Box
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        flexDirection: { md: 'row', xs: 'column' },
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    gap={2}
                                >
                                    <Box sx={{ paddingY: { md: '1rem' } }}>
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            {successStory}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ paddingY: { md: '1rem' } }}>
                                        <Typography
                                            color="white"
                                            sx={{ opacity: '80%', color: '#CBFDFD', textAlign: { md: 'start', xs: 'center' } }}
                                        >
                                            Submitted
                                        </Typography>
                                        <Typography
                                            color="white"
                                            variant="h5"
                                            sx={{ fontWeight: '600', textAlign: { md: 'start', xs: 'center' } }}
                                        >
                                            SUCCESS STORIES
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* Added */}
                            <Grid xs={12} sm={4}>
                                <Box
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        flexDirection: { md: 'row', xs: 'column' },
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    gap={2}
                                >
                                    <Box sx={{ paddingY: { md: '1rem' } }}>
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            {skills}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ paddingY: { md: '1rem' }, textAlign: { md: 'start', xs: 'center' } }}>
                                        <Typography
                                            color="white"
                                            sx={{ opacity: '80%', color: '#CBFDFD', textAlign: { md: 'start', xs: 'center' } }}
                                        >
                                            Added
                                        </Typography>
                                        <Typography
                                            color="white"
                                            variant="h5"
                                            sx={{ fontWeight: '600', textAlign: { md: 'start', xs: 'center' } }}
                                        >
                                            KNOWLEDGE & SKILLS
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* Submits */}
                            <Grid xs={12} sm={4}>
                                <Box
                                    sx={{
                                        background: `${member_id && member_id != 0 ? '#30C6D9' : '#1E7C88'}`,
                                        borderRadius: '5px',
                                        padding: '1rem',
                                        flexDirection: { md: 'row', xs: 'column' },
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    gap={2}
                                >
                                    <Box sx={{ paddingY: { md: '1rem' } }}>
                                        <Typography variant="h4" sx={{ fontWeight: '600' }} color="white">
                                            {voice}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ paddingY: { md: '1rem' } }}>
                                        <Typography
                                            color="white"
                                            sx={{ opacity: '80%', color: '#CBFDFD', textAlign: { md: 'start', xs: 'center' } }}
                                        >
                                            Submits
                                        </Typography>
                                        <Typography
                                            color="white"
                                            variant="h5"
                                            sx={{ fontWeight: '600', textAlign: { md: 'start', xs: 'center' } }}
                                        >
                                            VOICE
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* ----------- */}
                    <Grid item sm={12} sx={{ paddingY: '2rem' }}>
                        {fetched ? (
                            <Graphs
                                yAxisUser={yAxisUser}
                                yAxisSuccessPost={yAxisSuccessPost}
                                yAxisLeaderPost={yAxisLeaderPost}
                                yAxisNews={yAxisNews}
                                yAxisTask={yAxisTask}
                                yAxisEvent={yAxisEvent}
                                yAxisMeeting={yAxisMeeting}
                                yAxisProject={yAxisProject}
                            ></Graphs>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default OrganizationAnalytics;
