// material-ui
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';
import Grid from '@mui/material/Unstable_Grid2';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';
import Logo from '../../../../assets/static_images/logo.svg';
import Signature from '../../../../assets/static_images/signature.svg';
import { useState, useEffect } from 'react';
import FrontDrawer from 'layout/FrontLayout/Drawer/index';
import whiteLogo from '../../../../assets/static_images/whitelogo.svg';
import axios from 'lib/axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { leftSideDrawerOpen } from 'store/reducers/frontMenu';
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);

    const { openLeftSidebar } = useSelector((state) => state.frontMenu);
    const dispatch = useDispatch();

    const [companyData, setCompanyData] = useState();
    async function fetchCompanyData() {
        await axios
            .post(`/companies/show`, {
                company_id: JSON.parse(window.localStorage.getItem('authUser')).company_id
            })
            .then(({ data }) => {
                setCompanyData(data.company);
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        fetchCompanyData();
    }, []);
    // console.log(openLeftSidebar);
    return (
        <>
            <Box
                sx={{
                    maxWidth: '1600px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                mx="auto"
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box onClick={() => dispatch(leftSideDrawerOpen(true))} sx={{ display: { xs: 'block', md: 'none' } }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path
                                d="M19.1667 9.16663H0.833333C0.373096 9.16663 0 9.53972 0 9.99996C0 10.4602 0.373096 10.8333 0.833333 10.8333H19.1667C19.6269 10.8333 20 10.4602 20 9.99996C20 9.53972 19.6269 9.16663 19.1667 9.16663Z"
                                fill="white"
                            />
                            <path
                                d="M19.1667 3.33325H0.833333C0.373096 3.33325 0 3.70635 0 4.16659C0 4.62682 0.373096 4.99992 0.833333 4.99992H19.1667C19.6269 4.99992 20 4.62682 20 4.16659C20 3.70635 19.6269 3.33325 19.1667 3.33325Z"
                                fill="white"
                            />
                            <path
                                d="M19.1667 15H0.833333C0.373096 15 0 15.3731 0 15.8333C0 16.2936 0.373096 16.6667 0.833333 16.6667H19.1667C19.6269 16.6667 20 16.2936 20 15.8333C20 15.3731 19.6269 15 19.1667 15Z"
                                fill="white"
                            />
                        </svg>
                    </Box>
                    <Box sx={{ borderRight: '1px solid black', paddingRight: '1rem', display: { xs: 'none', md: 'block' } }}>
                        <img src={whiteLogo} alt="logo" style={{ height: '1.7rem' }} />
                    </Box>
                    <Box sx={{ paddingLeft: '1rem' }}>
                        {companyData ? (
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL}/Uploads/${companyData.company_id}/Logo/${companyData.company_logo}`}
                                alt="signature"
                                className="logo"
                            />
                        ) : (
                            <img src={Signature} alt="signature" />
                        )}
                    </Box>
                </Box>

                <Box sx={{ maxWidth: '800px', width: '100%', display: { xs: 'none', md: 'block' } }} mx="20px">
                    <Search />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box>
                        <Notification />
                    </Box>
                    <Box>
                        <Profile />
                    </Box>
                </Box>
            </Box>
            {openLeftSidebar && <FrontDrawer openLeftSidebar={openLeftSidebar} />}
        </>
    );
};

export default HeaderContent;
