/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { formatDate } from '../../helpers/formatData';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'lib/axios';
import { useTheme } from '@mui/material/styles';

function createData(no, engagement, count) {
    return { no, engagement, count };
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => descendingComparator(a, b, orderBy);
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const EngagementTable = ({ engagements, color }) => {
    console.log(color);
    const [order, setOrder] = useState('asc');
    const [orderBy] = useState('no');
    const [selected] = useState([]);
    const theme = useTheme();
    const [data, setData] = useState(engagements);
    const [rows, setRows] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [processing, setProcessing] = useState(false);
    const isSelected = (employeeName) => selected.indexOf(employeeName) !== -1;
    function sort_data() {
        setData(() => {
            return [
                ...data.sort((a, b) => {
                    if (order == 'asc') {
                        return a.value - b.value;
                    } else {
                        return b.value - a.value;
                    }
                })
            ];
        });
    }
    useEffect(() => {
        sort_data();
        let res = data?.map((item, index) => {
            return createData(index + 1, item.name, item.value);
        });
        setRows(res);
    }, [order]);
    return (
        <Box sx={{ width: '100%' }}>
            <Grid
                container
                display="flex"
                justifyContent="between"
                sm={12}
                sx={{ background: `${color ? color : '#1E7C88'}`, padding: '1rem', width: '100%' }}
            >
                <Grid item sm={9}>
                    <Typography variant="h5" color="white" sx={{ fontWeight: '500' }}>
                        ENGAGEMENT
                    </Typography>
                </Grid>
                <Grid item sm={3}>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            value={order}
                            onChange={(e) => {
                                setOrder(e.target.value);
                            }}
                        >
                            <MenuItem value="desc" sx={{ background: '#1E7C88' }}>
                                <Typography>High to Low</Typography>{' '}
                            </MenuItem>
                            <MenuItem value="asc">
                                <Typography>Low to High</Typography>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <TableContainer
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                    position: 'relative',
                    display: 'block',
                    maxWidth: '100%',
                    '& td, & th': { whiteSpace: 'nowrap' }
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    sx={{
                        '& .MuiTableCell-root:first-child': {
                            pl: 2
                        },
                        '& .MuiTableCell-root:last-child': {
                            pr: 3
                        }
                    }}
                >
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                            const isItemSelected = isSelected(row.engagement);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    sx={{
                                        '&:nth-of-type(odd)': {
                                            backgroundColor: `${color ? '#38E9FF' : 'rgba(30, 124, 136, 0.25)'}`
                                        },
                                        '&:last-child td, &:last-child th': { border: 0 }
                                    }}
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index}
                                    selected={isItemSelected}
                                >
                                    <TableCell component="th" id={labelId} scope="row" align="left">
                                        {/* <Link color="secondary" component={RouterLink} to=""> */}
                                        {row.no}
                                        {/* </Link> */}
                                    </TableCell>

                                    <TableCell align="left">{row.engagement}</TableCell>
                                    <TableCell align="left">
                                        <Typography color="primary" sx={{ fontWeight: '600' }}>
                                            {row.count}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default EngagementTable;
