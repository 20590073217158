/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Link, InputLabel, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';

const BasicInfo = ({ showSnack }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [userAbout, setUserAbout] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [hobby, setHobby] = useState('');
    const [permanentAddress, setPermanentAddress] = useState('');
    const [emergencyContact, setEmergencyContact] = useState('');
    const [bloodGroup, setBloodGroup] = useState('');
    const [warningMessages, setWarningMessages] = useState(false);
    function checkString() {
        const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const spaces = /\s/g;
        if (specialChar.test(userName) && spaces.test(userName)) {
            setWarningMessages(true);
            return true;
        } else {
            setWarningMessages(false);
            return false;
        }
    }
    function checkLength(length, string) {
        if (string && string.replace(/\s/g, '').length > length) {
            return string.substring(0, length);
            alert(`Maximum ${length} Characters Limit Exceeded!`);
        } else {
            return string;
        }
    }
    async function updateProfile() {
        const formData = new FormData();
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('user_name', userName);
        formData.append('about', userAbout);
        formData.append('phone_no', phone);
        formData.append('email', email);
        formData.append('hobby', hobby);
        formData.append('street_address', address);
        formData.append('permanent_address', permanentAddress);
        formData.append('emergency_contact', emergencyContact);
        formData.append('blood_group', bloodGroup);
        await axios
            .post('/users/update-profile', formData)
            .then(({ data }) => {
                if (data.success) {
                    if (showSnack) {
                        showSnack(!data.success, `${data.message}`);
                    }
                }
            })
            .catch((err) => console.log(err));
    }
    useEffect(() => {
        axios
            .post(`/users/user`, {
                id: JSON.parse(window.localStorage.getItem('authUser')).user_id.toString()
            })
            .then(({ data }) => {
                setFirstName(data.data.first_name);
                setLastName(data.data.last_name);
                setUserName(data.data.user_name);
                setUserAbout(data.data.about);
                setPhone(data.data.phone_no);
                setEmail(data.data.email);
                setAddress(data.data.street_address);
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <Grid
            sm={12}
            container
            display="flex"
            spacing={2}
            sx={{ padding: { md: '1.5rem', xs: '0rem' }, marginTop: { md: '0px', xs: '10px' }, height: '100%' }}
        >
            <Grid item xs={12} sx={{ paddingY: '.5rem' }}>
                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Edit Profile
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        First Name
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={firstName}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Last Name
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={lastName}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Username
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={userName}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setUserName(e.target.value);
                            if (checkString()) {
                            }
                        }}
                        placeholder="Type Here .."
                        fullWidth
                    />
                    <Typography sx={{ opacity: '60%' }} color={warningMessages ? '#FC5856' : '#000000'}>
                        Usernames must be between 3 and 20 characters in length, and may only contain letters, numbers, underscores,
                        hyphens, and/or periods. Special characters, spaces, and profanity are not allowed.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Phone
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={phone}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setPhone(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Emergent Contact number
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={emergencyContact}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setEmergencyContact(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Email
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={email}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                        disabled
                    />
                    <Typography sx={{ opacity: '60%' }}>Email Address Can't Be Changed</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Short Profile
                    </InputLabel>
                </Grid>
                <Grid item>
                    <textarea
                        placeholder="Type Here ..."
                        onChange={(e) => {
                            setUserAbout(e.target.value);
                            checkLength();
                        }}
                        rows="5"
                        cols="100"
                        fullWidth
                        style={{
                            borderRadius: '4px',
                            backgroundColor: 'rgba(0, 0, 0, 0.08);',
                            padding: '1rem',
                            opacity: '.9',
                            width: '100%',
                            fontSize: '1rem'
                        }}
                        type="text"
                        value={userAbout}
                    ></textarea>
                    <Typography sx={{ opacity: '60%' }}>Maximum 150 characters</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Hobby
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={hobby}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setHobby(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                        disabled
                    />
                    {/* <Typography sx={{ opacity: '60%' }}>Email Address Can't Be Changed</Typography> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Blood Group
                    </InputLabel>
                </Grid>
                <Grid item>
                    <OutlinedInput
                        type="text"
                        value={bloodGroup}
                        name="password"
                        color="primary"
                        onChange={(e) => {
                            setBloodGroup(e.target.value);
                        }}
                        placeholder="Type Here .."
                        fullWidth
                        disabled
                    />
                    {/* <Typography sx={{ opacity: '60%' }}>Email Address Can't Be Changed</Typography> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Present Address
                    </InputLabel>
                </Grid>
                <Grid item>
                    <textarea
                        placeholder="Type Here ..."
                        onChange={(e) => {
                            setAddress(e.target.value);
                        }}
                        rows="5"
                        cols="100"
                        fullWidth
                        style={{
                            borderRadius: '4px',
                            backgroundColor: 'rgba(0, 0, 0, 0.08);',
                            padding: '1rem',
                            opacity: '.9',
                            width: '100%',
                            fontSize: '1rem'
                        }}
                        type="text"
                        value={address}
                    ></textarea>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Permanent Address
                    </InputLabel>
                </Grid>
                <Grid item>
                    <textarea
                        placeholder="Type Here ..."
                        onChange={(e) => {
                            setPermanentAddress(e.target.value);
                        }}
                        rows="5"
                        cols="100"
                        fullWidth
                        style={{
                            borderRadius: '4px',
                            backgroundColor: 'rgba(0, 0, 0, 0.08);',
                            padding: '1rem',
                            opacity: '.9',
                            width: '100%',
                            fontSize: '1rem'
                        }}
                        type="text"
                        value={permanentAddress}
                    ></textarea>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid sm={12} container display="flex" alignItems="center" spacing={2}>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                updateProfile();
                            }}
                        >
                            Save Changes
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                // submitForm();
                            }}
                            sx={{ background: 'rgba(0, 0, 0, 0.38)' }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BasicInfo;
