/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import Grid from '@mui/material/Unstable_Grid2';
import EmojiIcon from '../../assets/static_images/emojiicon.svg';
import { Link, InputLabel, OutlinedInput } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Submit from '../../assets/static_images/submit.svg';
import axios from 'lib/axios';
import SuccessModal from 'components/modals/SuccessModal';

const CreateComment = ({
    setComments,
    comments,
    post,
    parentId = null,
    placeholder = 'Write Comment ..',
    model = 'post',
    showSnack = null,
    setCommentCount
}) => {
    const theme = useTheme();
    const [comment, setComment] = useState('');
    const [showEmojis, setShowEmojis] = useState(false);
    const [parentCommentId, setParentCommentId] = useState(parentId);
    const [url, setUrl] = useState(model == 'post' ? '/comments' : `/${model}-comments`);
    const submitComment = async () => {
        const formData = new FormData();
        if (model == 'post') {
            formData.append('post_id', parseInt(post.post_id));
        }
        if (model == 'event') {
            formData.append('event_id', parseInt(post.event_id));
        }
        if (model == 'policy') {
            formData.append('policy_id', parseInt(post.policy_id));
        }
        if (model == 'news') {
            formData.append('news_id', parseInt(post.news_id));
        }
        if (model == 'library') {
            formData.append('library_id', parseInt(post.entry_id));
        }
        if (model == 'reward') {
            formData.append('reward_id', parseInt(post.reward_id));
        }
        formData.append('comment_body', comment);
        if (parentCommentId) {
            formData.append('parent_comment_id', parseInt(parentCommentId));
        }

        if (comment != '') {
            axios.post(`${url}`, formData).then(({ data }) => {
                if (data.success) {
                    setComments((prev) => {
                        prev = [...prev, data.comment];
                        return prev;
                    });
                    if (setCommentCount) {
                        setCommentCount((count) => parseInt(count) + 1);
                    }
                    setComment('');
                    if (model == 'policy') {
                        setOpenSuccessModal(true);
                    } else {
                        if (showSnack) {
                            showSnack(false, `Your comment is submitted. Thank you`);
                        }
                    }
                }
            });
        }
    };
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    return (
        <Box display="flex" alignItems="center" style={{ zIndex: '1024' }}>
            <Box flex="1">
                <OutlinedInput
                    type="text"
                    value={comment}
                    color={theme.primary}
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                    placeholder={placeholder}
                    fullWidth
                    onKeyDown={(event) => {
                        if (event.code === 'Enter') {
                            submitComment();
                        }
                    }}
                />
            </Box>
            <Box>
                <Grid container display="flex" alignItems="center" spacing={3} style={{ padding: '.5rem' }}>
                    <Grid
                        item
                        className="cursor-pointer"
                        onClick={() => {
                            setShowEmojis(!showEmojis);
                        }}
                    >
                        {' '}
                        <img src={EmojiIcon} alt="EmojiIcon" />
                    </Grid>
                    <Grid
                        item
                        className="cursor-pointer"
                        onClick={() => {
                            submitComment();
                        }}
                    >
                        {' '}
                        <img src={Submit} alt="SubmitIcon" />
                    </Grid>
                </Grid>
                {showEmojis && (
                    <Modal open={showEmojis}>
                        <Box
                            sx={{ position: 'absolute', top: '50%', left: '50%', borderRadius: '15px', transform: 'translate(-50%, -50%)' }}
                        >
                            <Picker
                                data={data}
                                onEmojiSelect={(e) => {
                                    setShowEmojis(!showEmojis);
                                    setComment(`${comment} ${e.native}`);
                                }}
                            />
                        </Box>
                    </Modal>
                )}
            </Box>
            {openSuccessModal ? (
                <Modal
                    keepMounted
                    open={openSuccessModal}
                    onClose={setOpenSuccessModal}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <SuccessModal setOpenMessage={setOpenSuccessModal} is_task={true} message="Your comment is submitted. Thank you" />
                </Modal>
            ) : (
                <></>
            )}
        </Box>
    );
};

export default CreateComment;
