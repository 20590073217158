/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Link, InputLabel, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';

const ChangePassword = ({ showSnack }) => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showOld, setShowOld] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [missMatch, setMissMatch] = useState(false);
    const [warningMessages, setWarningMessages] = useState(false);
    async function updateProfile() {
        if (newPassword.length == 8) {
            if (newPassword !== confirmPassword) {
                setMissMatch(true);
            } else {
                const formData = new FormData();
                formData.append('old_pass', oldPassword);
                formData.append('new_pass', newPassword);
                await axios
                    .post('/users/update-profile', formData)
                    .then(({ data }) => {
                        if (data.success) {
                            if (showSnack) {
                                showSnack(false, `${data.message}`);
                            } else {
                                showSnack(true, `${data.message}`);
                            }
                        }
                    })
                    .catch((err) => console.log(err));
            }
        } else {
            setWarningMessages(true);
        }
    }
    return (
        <Grid sm={12} container display="flex" direction="column" alignItems="center" spacing={3} sx={{ padding: '1.5rem' }}>
            <Grid item sm={12} sx={{ paddingY: '1.5rem' }}>
                <Typography variant="h6" sx={{ fontWeight: '700' }}>
                    Edit Password
                </Typography>
            </Grid>
            <Grid item sm={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Old Password
                    </InputLabel>
                </Grid>
                <Grid item sm={12}>
                    <Grid container sm={12} display="flex" spacing={1} alignItems="center">
                        <Grid item sm={11}>
                            <OutlinedInput
                                value={oldPassword}
                                type={!showOld ? 'password' : 'text'}
                                name="password"
                                color="primary"
                                onChange={(e) => {
                                    setOldPassword(e.target.value);
                                }}
                                placeholder="Type Your Old Password Here .."
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={1}>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={() => {
                                    setShowOld((curr) => !curr);
                                }}
                                sx={{ background: 'rgba(0, 0, 0, 0.38)' }}
                            >
                                {showOld ? 'Hide' : 'Show'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                sm={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ margin: '2rem', padding: '2rem', background: ' rgba(30, 124, 136, 0.1)', borderRadius: '.5rem' }}
            >
                <Typography color={warningMessages ? '#FC5856' : 'primary'}>
                    Be at least 8 characters long Include a mix of uppercase and lowercase letters, numbers, and symbols Avoid common
                    phrases, personal information, and easily guessable words
                </Typography>
            </Grid>
            <Grid item sm={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        New Password
                    </InputLabel>
                </Grid>
                <Grid item>
                    <Grid container sm={12} display="flex" spacing={1} alignItems="center">
                        <Grid item sm={11}>
                            <OutlinedInput
                                type={!showNew ? 'password' : 'text'}
                                value={newPassword}
                                name="password"
                                color="primary"
                                onChange={(e) => {
                                    setNewPassword(e.target.value);
                                    setWarningMessages(false);
                                }}
                                placeholder="Type Your New Password Here .."
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={1}>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={() => {
                                    setShowNew((curr) => !curr);
                                }}
                                sx={{ background: 'rgba(0, 0, 0, 0.38)' }}
                            >
                                {showNew ? 'Hide' : 'Show'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Grid item>
                    {' '}
                    <InputLabel style={{ color: '#000000' }} htmlFor="email-login">
                        Retype Password
                    </InputLabel>
                </Grid>
                <Grid item>
                    <Grid container sm={12} display="flex" spacing={1} alignItems="center">
                        <Grid item sm={11}>
                            <OutlinedInput
                                type={!showConfirm ? 'password' : 'text'}
                                value={confirmPassword}
                                name="password"
                                color="primary"
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                    setMissMatch(false);
                                }}
                                placeholder="ReType Your New Password Here .."
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={1}>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                onClick={() => {
                                    setShowConfirm((curr) => !curr);
                                }}
                                sx={{ background: 'rgba(0, 0, 0, 0.38)' }}
                            >
                                {showConfirm ? 'Hide' : 'Show'}
                            </Button>
                        </Grid>
                    </Grid>
                    {missMatch ? <Typography color="#FC5856">Mew and ConfirmPassword Mismatch</Typography> : <></>}
                </Grid>
            </Grid>
            <Grid item sm={12}>
                <Grid sm={12} container display="flex" alignItems="center" spacing={2}>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                updateProfile();
                            }}
                        >
                            Save Changes
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            onClick={() => {
                                // submitForm();
                            }}
                            sx={{ background: 'rgba(0, 0, 0, 0.38)' }}
                        >
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChangePassword;
