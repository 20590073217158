// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const notice = {
    id: 'notice',
    title: 'notice',
    type: 'group',
    children: [
        {
            id: 'notice',
            title: 'Notice',
            type: 'item',
            url: '/dashboard/notice',
            breadcrumbs: false
        }
    ]
};

export default notice;
