/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Posts from 'pages/leaders-voice/Posts';
import Modal from '@mui/material/Modal';
import PostViewer from 'components/modals/PostViewer';
import ActivitySummary from './ActivitySummary';
import { Box } from '@mui/material';
import { Toolbar, useMediaQuery, Snackbar } from '@mui/material';
import About from 'pages/Profile/About';
import BasicInfo from './settings/BasicInfo';
import NewsFeed from './activities/NewsFeed';
import { Alert } from '@mui/lab';

const Index = () => {
    const [userData, setUserDate] = useState();
    const [posts, setPosts] = useState([]);
    const [offest, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sahredMedia, setSharedMedia] = useState();
    const [selected, setSelected] = useState();
    const [oepnViewer, setOpenViewer] = useState(false);
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [openSnack, setShowSnack] = useState(false);
    const [snackOptions, setSnackOptions] = useState({ isError: false, message: '' });
    function handleSnackClose() {
        setShowSnack(false);
    }
    return (
        <Grid sm={12} container>
            {!matchesXs ? (
                <Grid sm={0} md={3} item sx={{ borderRight: '1px solid rgba(0, 0, 0, 0.17)' }}>
                    <About />
                </Grid>
            ) : (
                <></>
            )}

            <Grid md={9} sm={12} item className="overflwNew">
                <BasicInfo
                    showSnack={(isError, message) => {
                        setSnackOptions({ isError, message });
                        setShowSnack(true);
                    }}
                />
            </Grid>
            {oepnViewer ? (
                <Modal
                    keepMounted
                    open={oepnViewer}
                    onClose={setOpenViewer}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Grid container display="flex" justifyContent="center" alignItems="center" sx={{ width: '100vw' }}>
                        <PostViewer
                            model={selected.model}
                            modelId={selected.model_id}
                            sharedId={selected.shared_id}
                            setOpenViewer={setOpenViewer}
                        />
                    </Grid>
                </Modal>
            ) : (
                <></>
            )}
            <Snackbar
                open={openSnack}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackClose} severity={snackOptions.isError ? 'error' : 'success'}>
                    {snackOptions.message}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Index;
