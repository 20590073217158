import React, { useEffect, useState } from 'react';
import axios from 'lib/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import avatar1 from '../../assets/images/users/avatar-1.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DatePicker from 'react-multi-date-picker';
import Calander from 'components/Calander';
import dayjs from 'dayjs';
import UpcomingList from 'components/UpcomingList';
import { Box } from '@mui/material';
import RightSideSkeleton from 'components/skeleton/RightSideSkeleton';
import { useSelector } from 'react-redux';
import { socket } from 'socket';
const Side = ({ model }) => {
    const [upcoming, setUpcoming] = useState([]);
    const [events, setEvents] = useState([]);
    const [requestLoaded, setRequestLoaded] = useState(false);
    const [links, setLinks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { formalEventType } = useSelector((state) => state.frontMenu);
    const [requestMonth, setRequestMonth] = useState(() => {
        return new Date().getMonth();
    });
    useEffect(() => {
        calculateUpcoming();
    }, [events]);
    useEffect(() => {
        fetchEvents();
    }, [formalEventType]);
    function calculateUpcoming() {
        setUpcoming((prev) => {
            prev = [
                ...events.map((event) => {
                    setLinks((links) => {
                        return [...links, `${window.location.origin}/single-view/type=event&model_id=${event.event_id}&comment_id=0`];
                    });
                    return new Date(event.due_data).getDate();
                })
            ];
            return prev;
        });
    }
    async function fetchEvents(date) {
        setEvents([]);
        setUpcoming([]);
        setRequestLoaded(false);
        setIsLoading(true);
        await axios
            .post(`/events/upcoming`, {
                month: date ? date : requestMonth,
                is_formal: formalEventType
            })
            .then(({ data }) => {
                if (data.success) {
                    setIsLoading(false);
                    setEvents(data.events);
                    calculateUpcoming();
                    setRequestLoaded(true);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
            });
    }

    async function updateHeighlitedDays(setHighlightedDays, month, setIsLoading) {
        setIsLoading(true);
        setLinks([]);
        await axios
            .post(`/events/upcoming`, {
                month: month
            })
            .then(({ data }) => {
                if (data.success) {
                    setHighlightedDays([
                        ...data.events.map((event) => {
                            setLinks((links) => {
                                return [
                                    ...links,
                                    `${window.location.origin}/single-view/type=event&model_id=${event.event_id}&comment_id=0`
                                ];
                            });
                            return new Date(event.due_data).getDate();
                        })
                    ]);
                    setIsLoading(false);
                }
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        listen_to_channel();
    }, []);
    function listen_to_channel() {
        socket.on('event_created', (event) => {
            setEvents((prev) => [event, ...prev]);
        });
        socket.on('event_deleted', (event) => {
            setEvents((prev) => {
                prev = prev
                    .filter((item) => {
                        if (item.event_id != event.event_id) {
                            return item;
                        }
                    })
                    .filter((element) => element !== null && element !== '' && element !== 'null');
                return prev;
            });
        });
    }
    // console.log('new upcoming', upcoming);
    return (
        <Grid sm={12} container sx={{ padding: '0px' }}>
            <Box
                sx={{
                    padding: '1rem',
                    borderRadius: '10px',
                    width: '100%',
                    height: { md: 'calc(100vh - 7.5rem)', xs: 'calc(100vh - 0.5rem)' },
                    overflowY: 'scroll',
                    border: '1px solid #1E7C8840',
                    backgroundColor: '#fff'
                }}
            >
                <Grid item sm={12}>
                    <Grid container sm={12} display="flex" direction="column">
                        <Grid item sm={12}>
                            <DatePicker multiple value={upcoming} />
                            {requestLoaded ? (
                                <Calander
                                    type="events"
                                    initialValue={dayjs(new Date())}
                                    upcoming={upcoming}
                                    loading={!requestLoaded}
                                    updateHeighlitedDays={updateHeighlitedDays}
                                    links={links}
                                />
                            ) : (
                                <></>
                            )}
                        </Grid>
                        <Grid item sm={12}></Grid>
                    </Grid>
                </Grid>
                <Box sx={{ marginTop: '1rem' }}>
                    {isLoading && (
                        <>
                            {new Array(10).fill('loading').map((_, index) => (
                                <RightSideSkeleton key={index} />
                            ))}
                        </>
                    )}
                    <UpcomingList list={events} model={model} />
                </Box>
            </Box>
        </Grid>
    );
};

export default Side;
