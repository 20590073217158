/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { Modal, Box, useTheme, useMediaQuery } from '@mui/material';
import axios from 'lib/axios';
import EventByDateModal from './modals/EventByDateModal';
import MeetingsByDateModal from './modals/MeetingsByDateModal';

const Calander = ({ initialValue, upcoming, loading, updateHeighlitedDays, links, type }) => {
    const pickersDayRef = useRef(null);
    const [isLoading, setIsLoading] = useState(loading);
    const [eventIsLoading, setEventIsLoading] = useState(false);
    const [highlightedDays, setHighlightedDays] = useState([]);
    const [formattedDate, setFormattedDate] = useState('');
    const [eventsByDate, setEventsByDate] = useState([]);
    const [meetingsByDate, setMeetingsByDate] = useState([]);
    useEffect(() => {
        setHighlightedDays(() => [...upcoming]);
    }, [upcoming]);
    const handleMonthChange = (date) => {
        updateHeighlitedDays(setHighlightedDays, date.$M, setIsLoading);
    };
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function ServerDay(props) {
        const { highlightedDays = [1, 2, 3], day, outsideCurrentMonth, ...other } = props;
        const index = highlightedDays.indexOf(props.day.date());
        const in_array = index >= 0;
        const isSelected = !props.outsideCurrentMonth && in_array;

        const formatDate = (date) => {
            return dayjs(date).format('YYYY-MM-DD HH:mm:ss.SSS');
        };

        return (
            <PickersDay
                {...other}
                ref={isSelected ? pickersDayRef : null}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
                selected={isSelected}
                disabled={day <= new Date(new Date() - 24 * 60 * 60 * 1000)}
                onClick={() => {
                    if (isSelected) {
                        const formattedDate = formatDate(day.toDate());
                        setFormattedDate(formattedDate);
                        handleOpen();

                        // location.replace(`${links[index]}`);
                    }
                }}
            />
        );
    }

    async function handleSubmit() {
        try {
            setEventIsLoading(true);
            const res = await axios.post(type === 'events' ? '/events/event-by-date' : '/meetings/meeting-by-date', {
                is_formal: true,
                target_date: formattedDate
            });

            if (res.data.success) {
                if (type === 'events') {
                    setEventsByDate(res.data.events);
                } else {
                    setMeetingsByDate(res.data.meetings);
                }

                setEventIsLoading(false);
            }
        } catch (error) {
            setEventIsLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        if (formattedDate && formattedDate != '') {
            handleSubmit();
        }
    }, [formattedDate]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                    sx={{ width: '100%' }}
                    defaultValue={initialValue}
                    loading={isLoading}
                    onMonthChange={handleMonthChange}
                    renderLoading={() => <DayCalendarSkeleton />}
                    slots={{
                        day: ServerDay
                    }}
                    slotProps={{
                        day: {
                            highlightedDays
                        }
                    }}
                />
            </LocalizationProvider>
            {open && (
                <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                    {type === 'events' ? (
                        <EventByDateModal
                            eventsByDate={eventsByDate}
                            formattedDate={formattedDate}
                            handleClose={handleClose}
                            eventIsLoading={eventIsLoading}
                        />
                    ) : (
                        <MeetingsByDateModal
                            handleClose={handleClose}
                            meetingsByDate={meetingsByDate}
                            formattedDate={formattedDate}
                            isLoading={eventIsLoading}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

export default Calander;
